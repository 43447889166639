import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

import { recipesReducer, recipeDetailsReducer, newRecipeReducer, recipeReducer,cloneRecipeReducer } from './reducers/recipeReducer'
import { productsReducer, productDetailsReducer,newReviewReducer, newProductReducer, productReducer } from './reducers/productReducers'
import { authReducer, userReducer, forgotPasswordReducer,allUsersReducer,userDetailsReducer } from './reducers/userReducers'
import { cartReducer } from './reducers/cartReducers';
import { newOrderReducer, newOrderGuessReducer,myOrdersReducer, orderDetailsReducer,allOrdersReducer, orderReducer} from './reducers/orderReducers'

const reducer = combineReducers({
    recipes: recipesReducer,
    recipeDetails: recipeDetailsReducer,
    newRecipe: newRecipeReducer,
    cloneRecipe: cloneRecipeReducer,
    recipe: recipeReducer,
    products: productsReducer,
    productDetails: productDetailsReducer,
    newProduct: newProductReducer,
    product: productReducer,
    auth: authReducer,
    user: userReducer,
    forgotPassword: forgotPasswordReducer,
    allUsers: allUsersReducer,
    userDetails: userDetailsReducer,
    cart: cartReducer,
    newOrder: newOrderReducer,
    newOrderGuess: newOrderGuessReducer,
    myOrders: myOrdersReducer,
    orderDetails: orderDetailsReducer,
    allOrders: allOrdersReducer,
    order: orderReducer,
    newReview: newReviewReducer

})

let initialState = {
    cart: {
        cartItems: localStorage.getItem('cartItems') 
            ? JSON.parse(localStorage.getItem('cartItems'))
            : [], 
        shippingInfo: localStorage.getItem('shippingInfo')
            ? JSON.parse(localStorage.getItem('shippingInfo'))
            : {}
    }
}

const middlware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middlware)))

export default store;