import React, { useState, useEffect } from 'react'
import TimerV60 from './TimerV60'
import { Link } from 'react-router-dom';
import ProgressBar from '../ProgressBar';


function TimerV60Two() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 7000)
  }, [])

  return (
    <>
    {loading === false ? (
    <>
        <TimerV60/>
    </>
      ) : (
        <div className="loading-timer-page">
          <div className='header-lab'>
                <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
          </div>
          <div className="load-timer">
          <p>BEFORE YOU BREW</p>
          <h1>MAKE SURE YOU RINSE YOUR FILTER</h1>
          <img src='/images/v60_icon-300x300.png' alt="v60"/>
          <div className='skip-link'>
            <Link to='/timer/v60/brew'>Skip</Link>
          </div>
          <ProgressBar/>
        </div>
        </div>
      )}
      </>
  );
}

export default TimerV60Two;