import React, { Fragment, useState } from 'react'
import Layout from '../layout/layout'
import { countries } from 'countries-list'
import { Link } from 'react-router-dom';

import MetaData from '../layout/MetaData'
import CheckoutSteps from './CheckoutSteps'

import { useDispatch, useSelector } from 'react-redux'
import { saveShippingInfo } from '../../actions/cartActions'
import './Checkout.scss'

const Shipping = ({ history }) => {

    const countriesList = Object.values(countries)

    const { shippingInfo } = useSelector(state => state.cart)

    const [address, setAddress] = useState(shippingInfo.address)
    const [city, setCity] = useState(shippingInfo.city)
    const [postalCode, setPostalCode] = useState(shippingInfo.postalCode)
    const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo)
    const [country, setCountry] = useState(shippingInfo.country)

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(saveShippingInfo({ address, city, phoneNo, postalCode, country }))
        history.push('/confirm')
    }

    return (
        <Layout>
        <Fragment>

            <MetaData title={'SHIPPING INFO'} />

            <div className="go-back">
                <Link to="/cart" className="back-link-checkout"><i class="fa fa-arrow-left"></i> Go Back To Cart</Link>
            </div>
            <CheckoutSteps shipping />

            <div className="shipping-page">
                        
                <div className="shipping-mobile">
                    <form className="shipping-form-mobile" onSubmit={submitHandler}>
                        <h1 className="mb-4">SHIPPING INFO</h1>
                        
                        <div className="form1">
                            <input
                                type="text"
                                id="address_field"
                                //className="form-control-one"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                            />
                            <label for="address_field" className="label-name">
                                <span  className="content-name">Address</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="text"
                                id="city_field"
                                //className="form-control-one"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                            <label for="city_field" className="label-name">
                                <span  className="content-name">City</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="phone"
                                id="phone_field"
                                //className="form-control-one"
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                                required
                            />
                            <label for="phone_field" className="label-name">
                                <span  className="content-name">Phone Number</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="number"
                                id="postal_code_field"
                                //className="form-control-one"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                required
                            />
                            <label for="postal_code_field" className="label-name">
                                <span  className="content-name">Postal Code</span>
                            </label>
                        </div>

                        <div className="form-group">
                        
                            <label htmlFor="country_field">Country</label>
                            <select
                                id="country_field"
                                className="form-control-one"
                                //value={country}
                                //onChange={(e) => setCountry(e.target.value)}
                                required
                            >
                                {/* {countriesList.map(country => (
                                    <option key={country.name} value={country.name}>
                                        {country.name}
                                    </option>
                                ))} */}
                                    <option>
                                    {/* <option key={country.name} value={country.name}> */}
                                        United states
                                    </option>
                            </select>
                        </div>

                        <div className="div_button">                   
                            <button
                                id="shipping_btn"
                                type="submit"
                                className="btn btn-block py-3"
                            >
                                CONTINUE
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </Fragment>
    </Layout>
    )
}

export default Shipping