import React, { Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './RecipeSheet.scss'
import Modal from 'react-modal';
import { DELETE_RECIPE_RESET } from '../../constants/recipeConstants';
import {useDispatch, useSelector} from 'react-redux';
import { getRecipeDetails, clearErrors,deleteRecipe} from '../../actions/recipeActions';
import { useAlert } from 'react-alert';
import MetaData from '../layout/MetaData';
import Loading from '../pages/loading/loading'
import Dropdown from "react-bootstrap/Dropdown";
import useToggle from "./useToggle.ts";

const pourImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319804/steps/rinse_ia1hvu.png"
    },
    {
        public_id: "steps/rinse_ia1hvu"
    }
];
const swirlImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319793/steps/swirl_f5s3mn.png"
    },
    {
        public_id: "steps/swirl_f5s3mn"
    }
];
const customImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1663213792/steps/question_ff3xfm.png"
    },
    {
        public_id: "steps/question_ff3xfm",
    }
];
const enjoyImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1645577261/steps/Group_20_nj2o6q.png"
    },
    {
        public_id: "steps/Group_20_nj2o6q"
    }
];
const bloomImage =[
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1637880877/steps/Screen_Shot_2021-11-25_at_5.50.59_PM_mdbmae.png"
    },
    {
        public_id: "steps/Screen_Shot_2021-11-25_at_5.50.59_PM_mdbmae"
    }
];
const waitImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319762/steps/wait_nq1gra.png"
    },
    {
        public_id: "steps/wait_nq1gra"
    }
]
const rinseImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319804/steps/rinse_ia1hvu.png"
    },
    {
        public_id: "steps/rinse_ia1hvu"
    }
];
const invertImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1664331507/steps/Group_8_vuftls.png"
    },
    {
        public_id: "steps/Group_8_vuftls"
    }
];


const RecipeDetails = ({ match,history }) => {
    const [isOn, toggleIsOn] = useToggle();
    const [isOnTwo, toggleIsOnTwo] = useToggle();
    const [isOnThree, toggleIsOnThree] = useToggle();
    

    const dispatch = useDispatch();
    const alert = useAlert();

    const {loading, error,error: deleteError, recipe,isDeleted,isUpdated} = useSelector(state => state.recipeDetails)
    const { user } = useSelector(state => state.auth)
   
    useEffect(() => {
        dispatch(getRecipeDetails(match.params.id))

        if(error){
            alert.error(error);
            dispatch(clearErrors())
        }
        

        if (isDeleted) {
            alert.success('Product deleted successfully');
            history.push('/recipes');
            dispatch({ type: DELETE_RECIPE_RESET })
        }
    }, [dispatch, alert,history, error, match.params.id,isUpdated, isDeleted,deleteError])

    const deleteRecipeHandler = (id) => {
        dispatch(deleteRecipe(id))
    }
    
    function fancyTimeFormat(duration){   
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }
    
    const arr = recipe && recipe.steps;
    // recOne.push(rec)
    
    // const durationTotal = rec.reduce((totalDuration, durations) => totalDuration + durations.duration, 0); 
    
    //  const totalz2 = () => {
    //     var recOne = []
    //     var rec = recipe.steps && recipe.steps;
    //     recOne.push(rec)
    //     // var rec = recipe && recipe.steps;
    //     var total2 = 0;
    //     for (var i = 0; i < recOne.length; i++) {
    //       total2 += recOne[i].duration;
    //     }
    //     return total2;
    // };
    // console.log(total2)
    //var arr = [{ 'credit': 1, 'trash': null }, { 'credit': 2, 'trash': null}];
    //const result = recipe.steps.filter(word => word.duration > 0);
    
    // const arr = [23,566,null,90,-32,undefined,32,-69,88,null];
    


    //  //3 dot dropdown
     const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <i class="fas fa-ellipsis-v"></i>
          {children}
      
        </a>
    ));

    // dynamic modal
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null);

    const expandModal = (steps) => {
        setSelectedProject(steps);
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setSelectedProject(null);
        setModalIsOpen(false);
    }


     // dynamic modal
    const [modalIsOpenTwo, setModalIsOpenTwo] = useState(false)
    //const [selectedProjectTwo, setSelectedProjectTwo] = useState(null);
 
    const expandModalTwo = () => {
        //setSelectedProjectTwo();
        setModalIsOpenTwo(true);
    }

    const closeModalTwo = () => {
       // setSelectedProjectTwo(null);
        setModalIsOpenTwo(false);
    }
    
    return (

        <Fragment>
        <MetaData title={recipe.name} />
        {loading ? <Loading/> : (
            <div className="sheet-recipe-full">
                <div className="sheet-recipe-page">
                    <div className="go-back-lab">
                        <Link to="/recipes" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Recipes</Link>
                        <div className='header-recipe-logo'>
                            <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
                        </div>
                    </div>
                    <form className="recipe-full-sheet" encType='multipart/form-data'>
                            <div className="recipe-title">
                            
                                <div className="recipe-title-row">
                                    <h5>{recipe.name}</h5>
                                </div>
                                <div className="recipe-title-row-sheet">
                                
                                    
                                    {/* <Link to={`/recipe/run/${recipe._id}`}>
                                        Start Recipe 
                                    </Link> */}
                                    {user && user._id == recipe.user &&
                                    <div className="row-flex  threeDots">
                                        <Dropdown >
                                            <Dropdown.Toggle as={CustomToggle}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                            <Dropdown.Header>Options</Dropdown.Header>
                                            <Dropdown.Item className="bg-dark text-light">
                                                <Link className="bg-dark text-light" to={`/recipe/update/${recipe._id}`}>
                                                    Edit
                                                </Link> 
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-dark text-light">
                                                <p onClick={() => deleteRecipeHandler(recipe._id)}>   
                                                    Delete
                                                </p>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-dark text-light">
                                                <p onClick={() => expandModalTwo()}>   
                                                    Info
                                                </p>
                                            </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    }
                                    {user && user._id !== recipe.user &&
                                    <div className="row-flex  threeDots">
                                        <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                        <Dropdown.Header>Options</Dropdown.Header>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <p className="text-muted">
                                                Download
                                            </p>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <p onClick={() => expandModalTwo()}>   
                                                Info
                                            </p>
                                        </Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    }
                                    {user == undefined &&
                                    <div className="row-flex  threeDots">
                                        <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                        <Dropdown.Header>Options</Dropdown.Header>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <Link to='/login'>
                                                <p>
                                                   Login
                                                </p>
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <p onClick={() => expandModalTwo()}>   
                                                Info
                                            </p>
                                        </Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="recipe-full-boxes-sheet">
                                <div className="recipe-details">
                                    <p><b>RECIPE STEPS</b></p>
                                    <div style={{overflow: 'auto', height: 'inherit', display: 'block', }}>        
                                        <DragDropContext>
                                            <Droppable droppableId="characters">
                                                {(provided) => (
                                                <div className="steps-box" {...provided.droppableProps} ref={provided.innerRef}>
                                                    {recipe.steps && recipe.steps.map(( steps) => {
                                                        return (
                                                            <>
                                                        <Draggable className="steps-box" key={steps.order} >
                                                            {(provided) => (
                                                            <div>

                                                                <div key={steps._id} className="step-box" onClick={() => expandModal(steps)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>                                                                {/* <div className="step-box" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}> */}
                                                                    
                                                                    <div className="step-box-section">
                                                                        <p>{ steps.name }</p>
                                                                        {/* <img className="recipe-card-img" src={steps.image[0].url} alt={steps.name}/> */}
                                                                        {steps.name == 'Pour' &&
                                                                            <img className="recipe-card-img" src={pourImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Swirl' &&
                                                                            <img className="recipe-card-img" src={swirlImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Rinse' &&
                                                                            <img className="recipe-card-img" src={rinseImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Enjoy' &&
                                                                            <img className="recipe-card-img" src={enjoyImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Invert' &&
                                                                            <img className="recipe-card-img" src={invertImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Custom Step' &&
                                                                            <img className="recipe-card-img" src={customImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Bloom' &&
                                                                            <img className="recipe-card-img" src={bloomImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Wait Time' &&
                                                                            <img className="recipe-card-img" src={waitImage[0].url} alt={steps.name}/>
                                                                        }
                                                                    </div>
                                                                    {/* <div className="step-box-section">
                                                                        <p placeholder="notes">{ steps.notes }</p>
                                                                    </div> */}
                                                                    <div className="step-box-section">
                                                                        <p>ORDER: { steps.order }</p>
                                                                        <div>
                                                                            {/* <p type="number" min='0' max='10'></p>01:<p type="number" min='0' max='59' placeholder="seconds">00</p> */}
                                                                            <p>DURATION: {fancyTimeFormat(steps.duration)}</p>
                                                                        </div>
                                                                        <p>POUR: { steps.pour }</p>
                                                                        <p>NOTES: {steps.notes}</p>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            )}
                                                        </Draggable>

                                                        {/* dynamic modal */}
                                                        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modal-box" size="lg"aria-labelledby="contained-modal-title-vcenter"centered style={{
                                                            overlay: {
                                                            position: 'fixed',
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            backgroundColor: 'transparent',
                                                            color: 'white',
                                                            
                                                            },
                                                            content: {
                                                            position: 'absolute',
                                                            top: '20%',
                                                            left: '20%',
                                                            right: '20%',
                                                            bottom: '20%',
                                                            border: '1px solid #ccc',
                                                            color: 'white',
                                                            overflow: 'auto',
                                                            WebkitOverflowScrolling: 'touch',
                                                            outline: 'none',
                                                            padding: '20px',
                                                            backgroundColor: '#343A40',
                                                            }
                                                        }}>
                                                            <h2>{selectedProject && selectedProject.name}</h2>
                                                            <img className="modal-image" src={selectedProject && selectedProject.image[0].url} alt={selectedProject && selectedProject.name}/>
                                                            <p>NOTES: {selectedProject && selectedProject.notes}</p>
                                                            <p>ORDER: {selectedProject && selectedProject.order}</p>
                                                            <p>POUR: {selectedProject && selectedProject.pour}</p>
                                                            <p>DURATION: {fancyTimeFormat(selectedProject && selectedProject.duration)}</p>
                                                            <div>
                                                            <button onClick={closeModal}>Close Modal</button>
                                                            </div>
                                                        </Modal>
                                                    </>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>

                                    {/* dynamic modal */}
                                    <Modal isOpen={modalIsOpenTwo} onRequestClose={closeModalTwo} className="modal-box" size="lg"aria-labelledby="contained-modal-title-vcenter"centered style={{
                                        overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'transparent',
                                        color: 'white',
                                        
                                        },
                                        content: {
                                        position: 'absolute',
                                        top: '20%',
                                        left: '20%',
                                        right: '20%',
                                        bottom: '20%',
                                        border: '1px solid #ccc',
                                        color: 'white',
                                        overflow: 'auto',
                                        WebkitOverflowScrolling: 'touch',
                                        outline: 'none',
                                        padding: '20px',
                                        backgroundColor: '#343A40',
                                        }
                                    }}>
                                        <h2>Info Section</h2>
                                        <div className="info-modal" style={{width: '100%'}}>
                                            <div className="info-modal-section" >
                                                <h4>Recipe Details</h4>
                                                <p>You can change the unit of measurement.</p>
                                            
                                            </div>
                                            <div className="info-modal-section">
                                                <h4>Recipe Steps</h4>
                                                <p>Recipe durations is added according to the steps duration.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <button onClick={closeModalTwo}>Close Modal</button>
                                        </div>
                                    </Modal>
                                </div>
                                <div className='recipe-details-panel'>

                                    <p><b>RECIPE DETAILS</b></p>
                                    <div className="recipe-sheet-top">
                                        <div className="recipe-sheet-top-left">
                                            <label>COFFEE TYPE</label>
                                            <p>{recipe.coffeeType}</p>
                                            {/* {recipe.user ? (
                                                    <img src={recipe.images[0].url} alt={recipe.name}/>
                                                ) :
                                                <div></div>
                                            } */}
                                            {recipe.method == "Chemex" &&
                                                <a href="https://www.chemexcoffeemaker.com/coffeemakers.html" className="d-flex flex-column justify-content-center align-items-center"><img src='/images/chemex_icon.png' alt={recipe.name}/></a>
                                            }
                                            {recipe.method == "AeroPress" &&
                                                <a href="https://aeropress.com/" className="d-flex flex-column justify-content-center align-items-center"><img src='/images/aeropress_icon.png' alt={recipe.name}/></a>
                                            }
                                            {recipe.method == "V60" &&
                                                <a href="https://global.hario.com/sp_v60series.html" className="d-flex flex-column justify-content-center align-items-center"><img src='/images/v60_icon-300x300.png' alt={recipe.name}/></a>
                                            }
                                            {recipe.method == "Turkish" &&
                                                <a href="https://www.chemexcoffeemaker.com/coffeemakers.html" className="d-flex flex-column justify-content-center align-items-center"><img src='/images/chemex_icon.png' alt={recipe.name}/></a>
                                            }
                                            {recipe.method == "Siphon" &&
                                                <span>Image Coming Soon</span>
                                            }
                                            {recipe.method == "Cold Brew" &&
                                                <span>Image Coming Soon</span>
                                            }
                                            {recipe.method == "French Press" &&
                                                <span>Image Coming Soon</span>
                                            }
                                            {recipe.method == "Coffee Maker" &&
                                                <span>Image Coming Soon</span>
                                            }

                                        </div>
                                        <div className="recipe-sheet-top-left">
                                            <label>DESCRIPTION</label>
                                            <p>{recipe.description}</p>
                                        </div>
                                    </div>
                               
                                    <div className="recipe-half-box-left-sheet">    
                                            <div className="form-group mb-0">
                                                <div className="d-flex flex-column justify-content-center align-items-start">
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <img style={{width: '22px', padding: '4px'}} src='/images/coffeebean2.png' alt='coffeebean'/>
                                                        <label className="mb-0" htmlFor="coffee_field">COFFEE</label>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex flex-row justify-content-start align-items-center">
                                                            <p className="">{isOn ? (recipe.coffee * 0.03527).toFixed(3) + ' oz': recipe.coffee +' g'}</p>
                                                            <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOn}>
                                                                Unit
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="form-group mb-0">
                                                <div className="d-flex flex-column justify-content-center align-items-start">
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <img style={{width: '23px', padding: '4px'}} src='/images/roast.png' alt='roast'/>
                                                        <label className="mb-0" htmlFor="roast_field">ROAST</label>
                                                    </div>
                                                    <div>
                                                        <p>{recipe.roast}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0">
                                                <div className="d-flex flex-column justify-content-center align-items-start">
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <img style={{width: '26px', padding: '4px'}} src='/images/grind.png' alt='grind'/>
                                                        <label className="mb-0" htmlFor="grind_field">GRIND</label>
                                                    </div>
                                                    <div>
                                                        <p>{recipe.grind}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0">
                                                <div className="d-flex flex-column justify-content-center align-items-start">
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <img style={{width: '21px', padding: '5px'}} src='/images/waterdrop2.png' alt='waterdrop'/>
                                                        <label className="mb-0" htmlFor="water_field">WATER</label>
                                                    </div>
                                              
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <p className="">{isOnTwo ? recipe.water + ' ml' : recipe.water +' g'}</p>
                                                        <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOnTwo}>
                                                            Unit
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0">
                                                <div className="d-flex flex-column justify-content-center align-items-start">
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <img style={{width: '18px', padding: '5px'}} src='/images/temp.png' alt='temp'/>
                                                        <label className="mb-0" htmlFor="temp_field">TEMPERATURE</label>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <p className="">{isOnThree ? ((5/9) * (recipe.temperature - 32)).toFixed(3) + ' c': recipe.temperature +' f'}</p>
                                                        <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOnThree}>
                                                            Unit
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0">
                                                <div className="d-flex flex-column justify-content-center align-items-start">
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <img style={{width: '23px', padding: '4px'}} src='/images/time.png' alt='time'/>
                                                        <label className="mb-0" htmlFor="grind_field">DURATION</label>
                                                    </div>
                                                    <div>
                                                        <p>{fancyTimeFormat(recipe.steps && recipe.steps.reduce((totalDuration, durations) => totalDuration + durations.duration, 0) )}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0">
                                                <div className="d-flex flex-column justify-content-center align-items-start">
                                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                                        <img style={{width: '20px', padding: '5px'}} src='/images/percent.png' alt='coffeebean'/>
                                                        <label className="mb-0" htmlFor="grind_field">RATIO</label>
                                                    </div>
                                                    <div>
                                                        <p>{recipe.ratio}</p>
                                                    </div>
                                                </div>
                                            </div>


                                            {recipe.method == "AeroPress" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">ORIENTATION</label>
                                                    {recipe.CustomMethodField == 2 &&
                                                        <p>Inverted</p> 
                                                    }
                                                    {recipe.CustomMethodField == 1 &&
                                                        <p>Standard</p> 
                                                    }
                                                </div>
                                            }

                                            {recipe.method == "Chemex" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">CUP SIZE</label>
                                                       
                                                    <p>{recipe.CustomMethodField}</p>
                                                </div>
                                            }

                                            {recipe.method == "V60" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">CUP SIZE</label>
                                                       
                                                    <p>{recipe.CustomMethodField}</p>
                                                </div>
                                            }
                                        </div>
                                    
                                </div>
                            </div>
                        </form>
                </div>
            </div>
        )}
        </Fragment>
    )
}

export default RecipeDetails;