import React,{Component} from 'react';
import Tilt from 'react-tilt';
import './prototype.styles.scss';


class Prototype extends Component {
    onMouseLeave(e) {
        console.log('clientY:', e.nativeEvent.clientY);
      }
    render() {
    return(
        <div className='prototype-section'>
            <div className='prototype'>
                <div className='prototype-full'>
                    <h1>HOW DOES IT WORK?</h1>
                    <Tilt className="Tilt"  onMouseLeave={this.onMouseLeave}>
                        <div className='Tilt-inner1'>
                            1
                        </div>
                        <div className="Tilt-inner">
                        MANAGE AND PERFECT YOUR BREWING WITH A FEW SINGLE 
                        CLICKS 👍
                        </div>
                    </Tilt>
                </div>
            </div>
            <div className='prototype'>
                <div className='prototype-left'>
                    <img src='/images/proto1.png'alt='' />
                </div>
                <div className='prototype-right'>
                    <Tilt className="Tilt"  onMouseLeave={this.onMouseLeave}>
                        <div className='Tilt-inner1'>
                            2
                        </div>
                        <div className="Tilt-inner">
                        CREATE COFFEE RECIPES, MANAGE TIME, TEMPERATURE AND RATIO.
                        </div>
                    </Tilt>
                </div>
            </div>
            <div className='prototype'>
                <div className='prototype-left1'>
                    
                    <Tilt className="Tilt"  onMouseLeave={this.onMouseLeave}>
                        <div className='Tilt-inner1'>
                            3
                        </div>
                        <div className="Tilt-inner">
                        TIME PHASE BREWING TIMER, ADJUSTED TO YOUR OWN SETTINGS.
                         GET THE MOST OUT OF YOUR CUPF OF COFFEE.
                        </div>
                    </Tilt>
                </div>
                <div className='prototype-right1'>
                    <img src='/images/proto2.png' alt='' />
                </div>
            </div>
        </div>
    )
    }
}

export default Prototype;