import React, {Component} from 'react'
import './TabsBrew.scss';
class Tabs extends Component {
    constructor(props) {
      super();
      this.state = {
        active: 0
      }
    }
    
    select = (i) => {
      let _this = this;
      return function() {
        _this.setState({
          active: i
        });
      }
    }
    
    renderTabs = () => {
      return React.Children.map(this.props.children, (item, i) => {
        if (i%2 === 0) {
          let active = this.state.active === i ? 'active' : '';
          return <button onClick={this.select(i)} className={`${active} tab`}>{item}</button>;
        }
      });
    }
    
    renderContent() {
      return React.Children.map(this.props.children, (item, i) => {
        if (i-1 === this.state.active) {
          return <div className='content'>{item}</div>;
        } else {
          return;
        }
      });
    }
    
    render() {
      return (
        <div className="tabs-brew">
          {this.renderTabs()}
          {this.renderContent()}
        </div>
      );
    }
  }
  
  class V60Tab extends Component {
    render() {
      return(
        <main>
          <Tabs>
          Pull the Perfect Shot
            <span>
                <ul className="tabs-brew-list">
                    <li>Espresso machine of your choice</li>
                    <li>Espresso portafilter</li>
                    <li>Around 18 - 20 grams on a fine grind of Craft Coffee beans</li>
                    <li>30 grams of water</li>
                    <li>extration time is 24 seconds</li>
                    <li>Burr grinder (recommended)</li>
                    <li>Scale (recommended)</li>
                    <li>Coffee to water ratio is 1:1</li>
                </ul>
            </span>
            
          </Tabs>
        </main>
      );
    }
  }
 
export default V60Tab;