import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import MetaData from '../layout/MetaData'
//import Loader from '../layout/Loader'
import Sidebar from './Sidebar'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetails, updateOrder,updateTrackingOrder, clearErrors } from '../../actions/orderActions'
import { UPDATE_ORDER_RESET } from '../../constants/orderConstants'

const ProcessOrder = ({ match }) => {

    const [status, setStatus] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');

    const alert = useAlert();
    const dispatch = useDispatch();

    const { loading, order = {} } = useSelector(state => state.orderDetails)
    const { shippingInfo, orderItems, paymentInfo, user, totalPrice, orderStatus,usedCoupon} = order
    const { error, isUpdated } = useSelector(state => state.order)

    const orderId = match.params.id;
    

    useEffect(() => {

        dispatch(getOrderDetails(orderId))

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }


        if (isUpdated) {
            alert.success('Order updated successfully');
            dispatch({ type: UPDATE_ORDER_RESET })
        }

    }, [dispatch, alert, error, isUpdated, orderId])

      //update order with tracking number
      const updateOrderTrackingHandler = (id) => {
        if(shippingInfo && shippingInfo.emailGuess == undefined){
            var recepiantEmail = user && user.email
            var recepaintName = user && user.name
        }
            else{
                recepiantEmail = shippingInfo && shippingInfo.emailGuess
                recepaintName = shippingInfo && shippingInfo.fullNameGuess
        }
        var address = shippingInfo && shippingInfo.address
        var city = shippingInfo && shippingInfo.city
        var postalCode = shippingInfo && shippingInfo.postalCode


        const formDataTwo = new FormData();
        formDataTwo.set('email', recepiantEmail);
        formDataTwo.set('status', status);
        formDataTwo.set('trackingNumber', trackingNumber);
        formDataTwo.set('name', recepaintName);

        formDataTwo.set('address',address);
        formDataTwo.set('city', city);
        formDataTwo.set('postalCode', postalCode);

        dispatch(updateTrackingOrder(id, formDataTwo))
    }

    const updateOrderHandler = (id) => {

        if(shippingInfo && shippingInfo.emailGuess == undefined){
            var recepiantEmail = user && user.email
            var recepaintName = user && user.name
        }
            else{
                recepiantEmail = shippingInfo && shippingInfo.emailGuess
                recepaintName = shippingInfo && shippingInfo.fullNameGuess
        }
        var address = shippingInfo && shippingInfo.address
        var city = shippingInfo && shippingInfo.city
        var postalCode = shippingInfo && shippingInfo.postalCode

        const formData = new FormData();
        formData.set('email', recepiantEmail);
        formData.set('status', status);
        formData.set('trackingNumber', trackingNumber);
        formData.set('name', recepaintName);

        formData.set('address',address);
        formData.set('city', city);
        formData.set('postalCode', postalCode);

        dispatch(updateOrder(id, formData))
    }

    const shippingDetails = shippingInfo && `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.postalCode}, United States`
    const isPaid = paymentInfo && paymentInfo.status === 'succeeded' ? true : false
    const isCoupon = order.usedCoupon && order.usedCoupon === true ? "Yes" : "No"
    // console.log(user && user.email)
    // console.log(shippingInfo && shippingInfo.emailGuess)
    return (
        <Fragment>
            <MetaData title={`Process Order # ${order && order._id}`} />
            <div className="row order-layout">
                <div className="col-12 col-md-2">
                    <Sidebar />
                </div>

                <div className="col-12 col-md-10">
                    <Fragment>
                        {loading ? <h1>LOADING...</h1> : (
                            <div className="row d-flex justify-content-around">
                                <div className="col-12 col-lg-7 order-details">

                                    <h2 className="my-5">Order # {order._id}</h2>

                                    <h4 className="mb-4">Shipping Info</h4>

                                    <p>Guess Users</p>
                                    <p><b>Name:</b> {shippingInfo && shippingInfo.fullNameGuess}</p>
                                    <p><b>email:</b> {shippingInfo && shippingInfo.emailGuess}</p>
                            
                                    <p>Auth User</p>
                                    <p><b>Name:</b> {user && user.name}</p>
                                    <p><b>email:</b> {user && user.email}</p>
                                   

                                    <p><b>Phone:</b> {shippingInfo && shippingInfo.phoneNo}</p>
                                    <p className="mb-4"><b>Address:</b>{shippingDetails}</p>
                                    <p><b>Amount:</b> ${totalPrice}</p>
                                    <p><b>Used Coupon:</b> {isCoupon}</p>

                                    <hr />

                                    <h4 className="my-4">Payment</h4>
                                    <p className={isPaid ? "greenColor" : "redColor"}><b>{isPaid ? "PAID" : "NOT PAID"}</b></p>

                                    <h4 className="my-4">Stripe ID</h4>
                                    <p><b>{paymentInfo && paymentInfo.id}</b></p>

                                    <h4 className="my-4">Order Status:</h4>
                                    <p className={order.orderStatus && String(order.orderStatus).includes('Delivered') ? "greenColor" : "redColor"} ><b>{orderStatus}</b></p>

                                    <h4 className="my-4">Tracking Number</h4>
                                    <p>{order.trackingNumber}</p>

                                    <h4 className="my-4">created At</h4>
                                    <p>{order.createdAt}</p>

                                    <h4 className="my-4">Delivered At</h4>
                                    <p>{order.deliveredAt}</p>

                                    <h4 className="my-4">Order Items:</h4>

                                    <hr />
                                    <div className="cart-item my-1">
                                        {orderItems && orderItems.map(item => (
                                            <div key={item.product} className="row my-5">
                                                <div className="col-4 col-lg-2">
                                                    <img src={item.image} alt={item.name} height="45" width="65" />
                                                </div>

                                                <div className="col-5 col-lg-5">
                                                    <Link to={`/products/${item.product}`}>{item.name}</Link>
                                                </div>


                                                <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                                                    <p>${item.price}</p>
                                                </div>

                                                <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                                                    <p>{item.quantity} Piece(s)</p>
                                                </div>

                                                <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                                                    <p>{item.size} Piece(s)</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <hr />
                                </div>

                                <div className="col-12 col-lg-3 mt-5">
                                <h4 className="my-4">Status</h4>

                                <div className="form-group">
                                    <select
                                        className="form-control"
                                        name='status'
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <option value="Processing">Processing</option>
                                        <option value="Shipped">Shipped</option>
                                        <option value="Delivered">Delivered</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <h4 className="my-4">Tracking Number</h4>
                                    <input className="form-control" type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                                </div>
                                <button className="btn btn-primary btn-block" onClick={() => updateOrderTrackingHandler(order._id)}>
                                    Update Shipping and send tracking
                                </button>
                                <button className="btn btn-primary btn-block" onClick={() => updateOrderHandler(order._id)}>
                                    Update Delivered or Processing
                                </button>

                                    {/* <div>
                                        <h4 className="my-4">Tracking Number</h4>
                                        <input className="form-control" type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                                        <button onClick={() => updateOrderTrackingHandler(order._id)}>Create Tracking Email</button>
                                    </div> */}
                                </div>

                            </div>
                        )}
                    </Fragment>
                </div>
            </div>

        </Fragment>
    )
}

export default ProcessOrder