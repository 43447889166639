import React, { Fragment} from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import Layout from '../layout/layout';

const OrderSuccess = () => {

    return (
        <Layout>
        <Fragment>
            <MetaData title={'ORDER SUCCESS'} />
            <div className="justify-content-center">
                <div className="col-12 mt-5 mb-5 text-center">
                    <img className="my-5 img-fluid d-block mx-auto" src="/images/logo.png" alt="Order Success" width="200" height="200" />

                    <h2>Your Order has been placed successfully.</h2>
                    <p>You will receive email when your order has been shipped.
                         In the mean time you can check the status in the orders page</p>

                    <Link to="/orders/me">Go to Orders</Link>
                </div>
            </div>
        </Fragment>
        </Layout>
    )
}

export default OrderSuccess