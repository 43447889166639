import React, {Component} from 'react';
//import aeroPressIcon from '../../../assets/aeropress_icon.png'
import { Link } from 'react-router-dom';
import AeroPressTab from './tabs/aeropress-tab'
import './brew.styles.scss'
import Layout from '../../layout/layout';
import MetaData from '../../layout/MetaData';

class AeroPressPage extends Component{
    render(){
        return(
            <Layout>
            <MetaData title={"AEROPRESS"} />
            <div className="method-page">
                <div className="method-top">
                    <div className="method-img">
                        <img src='/images/aeropress_icon.png' alt="aeropress"/>
                    </div>
                    <div className="method-description">
                        <h1>AEROPRESS</h1>
                        <p>A small, two-tubed device, the AeroPress 
                        quickly brews a full-bodied coffee by allowing 
                        the user to push the water through the puck. 
                        Unlike pour over devices, it’s inspired by espresso 
                        machine technology – except electricity and levers 
                        are replaced by human pressure.</p>

                        <p>It also has similarities to the French press, 
                        although it’s become embraced by the specialty 
                        coffee industry in a way that the humble French 
                        press has yet to achieve. What’s more, the brew 
                        time (and recommended water temperature) is 
                        significantly shorter with the AeroPress.</p>
                    </div>
                </div>
                <div className="method-middle">
                    <div className="method-tabs">
                            <AeroPressTab />
                    </div>
                    <div className="method-requirements">
                        <h1>BEFORE YOU BREW</h1>
                        <p>Make sure your AeroPress is clean and completely dry 
                        before using, or else the plunger tip may not be able 
                        to form a perfect seal inside the chamber.
                        </p>

                        <p>For best results, we strongly recommend starting with whole 
                        bean coffee and grinding with a burr grinder immediately 
                        before brewing. If you grind your coffee too soon, it will 
                        quickly lose many of the compounds that give it such delightful 
                        aromas and flavors.</p>
                    </div>
                </div>
                
                <div className="method-bottom">
                    <Link className="method-button" to='/timer/aeropress'>BREW AEROPRESS TIMER</Link>
                </div>
            </div>
            </Layout>
        )
    }
}

const AeroPressBox = () => (
    <div className="method-box"><Link className="method-box-link" to='/aeropress'>
        <img src='/images/aeropress_icon.png' alt="aeropress"/>
        <h3>AeroPress</h3>
        <p>Pressure</p>
        <p>Fine Grind</p>
        <p>2-4 min</p>
    </Link></div>
);

export default AeroPressPage;
export {AeroPressBox};