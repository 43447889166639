import React, { Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile, loadUser, clearErrors } from '../../actions/userActions'
import { UPDATE_PROFILE_RESET } from '../../constants/userConstants'

const UpdateProfile = ({ history }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('/images/default_avatar.jpg')
    const [checked, setChecked] = useState()
    const [favoriteCoffee, setFavoriteCoffee] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const handleClick = () => setChecked(!checked)

    const alert = useAlert();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { error, isUpdated, loading } = useSelector(state => state.user)

    useEffect(() => {

        if (user) {
            setName(user.name);
            setEmail(user.email);
            setFavoriteCoffee(user.favoriteCoffee);
            setState(user.state);
            setCountry(user.country);
            setAvatarPreview(user.avatar.url)
            setChecked(user.newsletter);
        }

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (isUpdated) {
            alert.success('User updated successfully')
            dispatch(loadUser());

            history.push('/profiletabs')

            dispatch({
                type: UPDATE_PROFILE_RESET
            })
        }

    }, [dispatch, alert, error, history, isUpdated, user])

    const submitHandler = (e) => {
        e.preventDefault();

        // const formData = new FormData();
//         formData.set('name', name);
//         formData.set('email', email);
//         formData.set('password', password);
//         formData.set('avatar', avatar);
// console.log(formData)
//         dispatch(register(formData))

        const formData = new FormData();
        formData.set('name', name);
        formData.set('email', email);
        formData.set('avatar', avatar);
        formData.set('favoriteCoffee', favoriteCoffee);
        formData.set('state', state);
        formData.set('country', country);
        formData.set('newsletter', checked);

        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var json = object
        dispatch(updateProfile(json))
    
    }

    const onChange = e => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setAvatarPreview(reader.result)
                setAvatar(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])

    }
    return (
        <Fragment>
            <MetaData title={'UPDATE PROFILE'} />

            <div className="login-page">
                <div className="go-back">
                    <Link to="/profiletabs" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Profile</Link>
                </div>
                <div className="login">
                
                    <form className="login-form" onSubmit={submitHandler} encType='application/json'>
                        <h1 className="mt-2 mb-5">UPDATE PROFILE</h1>

                        <div className="form1">
                            <input
                                type="name"
                                id="name_field"
                                className="form-control"
                                name='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label for="name_field" className="label-name">
                                <span  className="content-name">NAME</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="email"
                                id="email_field"
                                className="form-control"
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label for="email_field" className="label-name">
                                <span  className="content-name">EMAIL</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="text"
                                id="favorite_coffee_field"
                                className="form-control"
                                name='favoriteCoffee'
                                value={favoriteCoffee}
                                onChange={(e) => setFavoriteCoffee(e.target.value)}
                            />
                            <label for="favorite_coffee_field" className="label-name">
                                <span  className="content-name">FAVORITE COFFEE SHOP</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="text"
                                id="state_field"
                                className="form-control"
                                name='state'
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                            <label for="state_field" className="label-name">
                                <span  className="content-name">STATE/TERRITORY</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="text"
                                id="country_field"
                                className="form-control"
                                name='name'
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                            <label for="country_field" className="label-name">
                                <span  className="content-name">COUNTRY</span>
                            </label>
                        </div>

                        <div className='form-group'>
                            <label htmlFor='avatar_upload'>AVATAR</label>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <figure className='avatar mr-3 item-rtl'>
                                        <img
                                            src={avatarPreview}
                                            className='rounded-circle'
                                            alt='Avatar Preview'
                                        />
                                    </figure>
                                </div>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        name='avatar'
                                        className='custom-file-input'
                                        id='customFile'
                                        //accept="iamges/*"
                                        onChange={onChange}
                                        //multiple="multiple"
                                        //accept="image/*"
                                        accept="image/jpeg,image/png,image/webp"
                                        tabIndex="-1"
                                    />
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        CHOOSE AVATAR
                                </label>
                                </div>
                            </div>
                        </div>

                         <div className="d-flex flex-row justify-content-start align-items-center pl-5 pr-5">
                            <p className="m-0 pr-1">Would you like to subscribe to our newsletter</p>
                            <input onClick={handleClick} checked={checked} type="checkbox" />
                        </div>
        
                        <div className="div_button">  
                            <button type="submit" className="btn btn-block py-3" disabled={loading ? true : false} >UPDATE</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default UpdateProfile