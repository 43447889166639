import React, {Component} from 'react';
//import v60Icon from '../../../assets/v60_icon-300x300.png'
import { Link } from 'react-router-dom';
import V60Tab from './tabs/v60-tab'
import MetaData from '../../layout/MetaData';
import './brew.styles.scss'
import Layout from '../../layout/layout';

class V60Page extends Component{
    render(){
        return(
            <Layout>
            <MetaData title={"V60"} />
            <div className="method-page">
                <div className="method-top">
                    <div className="method-img">
                        <img src='/images/v60_icon-300x300.png' alt="v60"/>
                    </div>
                    <div className="method-description">
                        <h1>V60</h1>
                        <p>Nuanced and versatile, the Hario is an elegant brewer 
                        for those who want to perfect the pour. It’s great for 
                        folks who are looking for complete control over brewing 
                        extraction. The key here is to pour slow. The entire brew 
                        process for a 10oz mug takes about three minutes.</p>

                        <p>Simple and elegant, the Hario V60 puts you in 
                        complete control of the brewing process, makes 
                        cleanup a breeze, and can easily be tossed into 
                        your overnight bag. Follow this guide to brew the 
                        perfect pour over.</p>
                    </div>
                </div>
                <div className="method-middle">
                    <div className="method-tabs">
                            <V60Tab />
                    </div>
                    <div className="method-requirements">
                        <h1>BEFORE YOU BREW</h1>
                        <p>For best results, we strongly recommend 
                        starting with whole bean coffee and grinding 
                        with a burr grinder immediately before brewing. 
                        If you grind your coffee too soon, it will quickly 
                        lose many of the compounds that give it such 
                        delightful aromas and flavors.
                        </p>

                        <p>While it may seem complicated, we think using 
                        a scale actually makes brewing easier by 
                        taking any guesswork out of the equation.</p>
                    </div>
                </div>
                <div className="method-bottom">
                    <Link className="method-button" to='/timer/v60'>BREW V60 TIMER</Link>
                </div>
            </div>
            </Layout>
        )
    }
}

const V60Box = () => (
    <div className="method-box"><Link className="method-box-link"  to='/v60'>
        <img src='/images/v60_icon-300x300.png' alt="v60" />
        <h3>v60</h3>
        <p>Drip</p>
        <p>Medium Grind</p>
        <p>2-3 min</p>
    </Link></div>
);

export default V60Page;
export {V60Box};