import React from 'react';
import { Link } from 'react-router-dom'
import './Product.styles.scss'

const Product = ({product, col}) => {
    return(
        
        <div className="method-box-shop">
            <div className="card-body">
            <p className="card-title">
                <Link className="card-title-link" to={`/product/${product._id}`}>{product.name}</Link>
            </p>
            {/* <div className="ratings mt-auto">
                <div className="rating-outer">
                <div className="rating-inner" style={{ width: `${(product.ratings / 5)* 100}%`}}></div>
                </div>
                <span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
            </div> */}
            <p className="card-text">${product.price}</p>
            <img className="card-img-top mx-auto" src={product.images[0].url} alt={product.title}/>
            <Link to={`/product/${product._id}`} className=" btn-block">View Details</Link>
            </div>
        </div>
        
    )

}

export default Product