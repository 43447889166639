export const ADMIN_RECIPES_REQUEST = 'ADMIN_RECIPES_REQUEST'
export const ADMIN_RECIPES_SUCCESS = 'ADMIN_RECIPES_SUCCESS'
export const ADMIN_RECIPES_FAIL = 'ADMIN_RECIPES_FAIL'

export const ALL_RECIPES_REQUEST = 'ALL_RECIPES_REQUEST'
export const ALL_RECIPES_SUCCESS = 'ALL_RECIPES_SUCCESS'
export const ALL_RECIPES_FAIL = 'ALL_RECIPES_FAIL'

export const NEW_RECIPE_REQUEST = 'NEW_RECIPE_REQUEST'
export const NEW_RECIPE_SUCCESS = 'NEW_RECIPE_SUCCESS'
export const NEW_RECIPE_RESET = 'NEW_RECIPE_RESET'
export const NEW_RECIPE_FAIL = 'NEW_RECIPE_FAIL'

export const DELETE_RECIPE_REQUEST = 'DELETE_RECIPE_REQUEST'
export const DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS'
export const DELETE_RECIPE_RESET = 'DELETE_RECIPE_RESET'
export const DELETE_RECIPE_FAIL = 'DELETE_RECIPE_FAIL'

export const UPDATE_RECIPE_REQUEST = 'UPDATE_RECIPE_REQUEST'
export const UPDATE_RECIPE_SUCCESS = 'UPDATE_RECIPE_SUCCESS'
export const UPDATE_RECIPE_RESET = 'UPDATE_RECIPE_RESET'
export const UPDATE_RECIPE_FAIL = 'UPDATE_RECIPE_FAIL'

export const RECIPE_DETAILS_REQUEST = 'RECIPE_DETAILS_REQUEST'
export const RECIPE_DETAILS_SUCCESS = 'RECIPE_DETAILS_SUCCESS'
export const RECIPE_DETAILS_FAIL = 'RECIPE_DETAILS_FAIL'

export const NEW_REVIEW_REQUEST = 'NEW_REVIEW_REQUEST'
export const NEW_REVIEW_SUCCESS = 'NEW_REVIEW_SUCCESS'
export const NEW_REVIEW_RESET = 'NEW_REVIEW_RESET'
export const NEW_REVIEW_FAIL = 'NEW_REVIEW_FAIL'

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_RESET = 'DELETE_REVIEW_RESET'
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'