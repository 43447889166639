import React from 'react';
import Layout from '../../layout/layout';
import {Link} from 'react-router-dom'
import MetaData from '../../layout/MetaData';
import './about-page.styles.scss';
import AboutHero from './about-hero';
import swal from 'sweetalert';


class AboutPage extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
          email: '',
          name: '',
          message: ''
        }
    }

    handleSubmit(e){
        e.preventDefault();
        fetch('/send', {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(
    (response) => (response.json())
      ).then((response)=> {
    if (response.status === 'success') {
        swal("MESSAGE HAS BEEN SEND!", "", "success");
      //alert("Message Sent."); 
      this.resetForm()
    } else if(response.status === 'fail') {
        swal("MESSAGE FAILED TO SEND.", "", "error");
        //alert(state.email)
    }
  })
      }
    
      resetForm(){
        this.setState({ email: '', name: '', message: ''})
      }

    render() {
    return(
            <Layout>
                <MetaData title={"GET TO KNOW US"} />
                <div className='about-container'>
                    <AboutHero/>
                    <div className='about-product'>
                        <div className='about-product-section'>
                            <div className='about-product-details'>
                                <h4>OUR JOURNEY AND THE OUTCOME</h4>
                                <p>We enjoy discovering new and exiting flavors. Our purpose 
                                is to deliver a consistently exceptional coffee daily experience
                                </p>
                            </div>
                            <div className='about-product-details'>
                                <h4>SOURCE OF OUR COFFEE</h4>
                                <p>
                                Credit where credit is due
                                When you order a bag of coffee, 
                                it’s roasted fresh and arrives at 
                                your door soon after—promising highest quality.
                                </p>
                            </div>
                            <div className='about-product-details'>
                                <h4>LEARNING AND SHARING KNOWLEDGE</h4>
                                <p>
                                We love staying in touch with our community 
                                to share our progress, inspire to action and 
                                start conversations about coffee, business, 
                                culture and sustainability.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="about-form">
                        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                            <div className="about-input">
                            <input  type="email"  className="question" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} required autoComplete="off" />
                            <label htmlFor="email"><h1>Email?</h1></label>
                            </div>
                            <div className="about-input">
                            <input type="text" className="question" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)}  required autoComplete="off" />
                            <label htmlFor="name"><h1>Name?</h1></label>
                            </div>
                            <div className="about-input">
                            <textarea rows="1" className="question" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)}required autoComplete="off"></textarea>
                            <label htmlFor="message"><h1>Feedback</h1></label>
                            </div>
                            <div className="about-button">
                            <button type="submit" id="login_btn" >Send</button>
                            </div>
                        </form>
                    </div>
                    <div className='positions'>
                        <h1>Open Positions In Engineering</h1>
                        <Link to='/position-one' className='open-positions'>
                            <div>
                                <p>Full-Stack Developer</p>
                                <span>Atlanta/ Remote</span>
                            </div>
                            <div>
                                <i class="fas fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </Layout>
    )
    }
    onNameChange(event) {
        this.setState({name: event.target.value})
    }
  
    onEmailChange(event) {
        this.setState({email: event.target.value})
    }
  
    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
}

export default AboutPage;