import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'

import MetaData from '../layout/MetaData';
import Loading from '../pages/loading/loading';

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { myOrders, clearErrors } from '../../actions/orderActions'

const ListOrders = () => {

    const alert = useAlert();
    const dispatch = useDispatch();

    const { loading, error, orders } = useSelector(state => state.myOrders);

    useEffect(() => {
        dispatch(myOrders());

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, alert, error])

    const setOrders = () => {
        const data = {
            columns: [
                // {
                //     label: 'Order ID',
                //     field: 'id',
                //     sort: 'asc'
                // },
                // {
                //     label: 'Num of Items',
                //     field: 'numOfItems',
                //     sort: 'asc'
                // },
                {
                    label: 'Amount',
                    field: 'amount',
                    sort: 'asc'
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc'
                },
            ],
            rows: []
        }

        orders.forEach(order => {
            data.rows.push({
                id: order._id,
                numOfItems: order.orderItems.length,
                amount: `$${order.totalPrice}`,
                status: order.orderStatus && String(order.orderStatus).includes('Delivered')
                    ? <p style={{ color: 'green' }}>{order.orderStatus}</p>
                    : <p style={{ color: 'red' }}>{order.orderStatus}</p>,
                actions:
                    <Link to={`/order/${order._id}`} className="btn btn-primary">
                        <i className="fa fa-eye"></i>
                    </Link>
            })
        })

        return data;
    }

    return (
        <Fragment>

            <MetaData title={'MY ORDERS'} />
            <div className="go-back">
                <Link to="/profiletabs" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Profile</Link>
            </div>
            <div className='full-page'>
            <div className='cart-page'>

            <h4 className="my-2">MY ORDERS</h4>

            {loading ? <Loading/> : (
                <MDBDataTable
                    data={setOrders()}
                    className="px-2"
                    bordered
                    striped
                    hover
                />
            )}
            </div>
            </div>

        </Fragment>
    )
}

export default ListOrders  