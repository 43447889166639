import React, { useState, useEffect } from 'react'
//import logo from './logo.svg';
//import './App.css';
//import LoadingScreen from './loading'
import { Link } from 'react-router-dom';
import TimerV60Two from './TimerV60Two';
import ProgressBar from '../ProgressBar';


function TimerV60One() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 7000)
  }, [])

  return (
    <>
    {loading === false ? (
    <>
        <TimerV60Two/>
    </>
      ) : (
        <div className="loading-timer-page">
            <div className='header-lab'>
                <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
            </div>
          <div className="load-timer">
            <p>BEFORE YOU BREW</p>
            <h1>GRING COFFEE TO A COARSE TEXTURE</h1>
            <img src='/images/v60_icon-300x300.png' alt="v60"/>
            <div className='skip-link'>
              <Link to='/timer/v60/brew'>Skip</Link>
            </div>
            <ProgressBar/>
        </div>
        </div>
      )}
      </>
  );
}

export default TimerV60One;