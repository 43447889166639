import React from 'react';

const AboutHero = () => {
    return(
        <div className="about-banner">
            <div className="about-image">
                <img src='/images/barista02-01.png' alt='about'/>
            </div>
            <div className='about-title'>
                <p className="about-text">
                    We believe coffee is more than just careful farming, 
                    intentional processing and expert roasting. Coffee 
                    is an opportunity to do good, and it keeps us moving foward. 
                </p>
                <p className="about-text">
                    Engineering a better cup of coffee isn't difficult. 
                    But first you need to figure out what "better" means to you. 
                    There is no single right cup of coffee, there's 
                    just the best version of what you love, we are all <i>BREWING ENGINEERS.</i>
                </p>
            </div>
        </div>
    )
}

export default AboutHero;