import React, {Component} from 'react';
//import chemexIcon from '../../../assets/chemex_icon.png'
import MetaData from '../../layout/MetaData';
import { Link } from 'react-router-dom';
import ChemexTab from './tabs/chemex-tab'
import Layout from '../../layout/layout';
import './brew.styles.scss'


class ChemexPage extends Component{
    render(){
        return(
            <Layout>
            <MetaData title={"CHEMEX"} />
            <div className="method-page">
                <div className="method-top">
                    <div className="method-img">
                        <img src='/images/chemex_icon.png' alt="chemex"/>
                    </div>
                    <div className="method-description">
                        <h1>CHEMEX</h1>
                        <p>The Chemex Coffeemaker was invented in 1941 by Dr. 
                        Peter Schlumbohm PhD. Made simply from non-porous, 
                        borosilicate glass, it brews coffee without imparting any 
                        flavors of its own. On permanent display at MOMA New York 
                        and other fine museums, it is truly a work of art.</p>

                        <p>The Chemex consists of an narrow-waist glass flask and 
                        uses proprietary paper filters, made of chemically 
                        bonded paper (of thicker-gauge paper than the standard 
                        paper filters for a drip-method coffeemaker)</p>
                    </div>
                </div>
                <div className="method-middle">
                    <div className="method-tabs">
                            <ChemexTab />
                    </div>
                    <div className="method-requirements">
                        <h1>BEFORE YOU BREW</h1>
                        <p>For best results, we strongly recommend starting with whole 
                        bean coffee and grinding with a burr grinder immediately 
                        before brewing. If you grind your coffee too soon, it will 
                        quickly lose many of the compounds that give it such delightful 
                        aromas and flavors.
                        </p>

                        <p>While it may seem complicated, we think using a scale actually 
                        makes brewing easier by taking any guesswork out of the equation.</p>
                    </div>
                </div>
                <div className="method-bottom">
                    <Link className="method-button" to='/timer/chemex'>BREW CHEMEX TIMER</Link>
                </div>
            </div>
            </Layout>
        )
    }
}

const ChemexBox = () => (
    <div className="method-box"><Link className="method-box-link" to='/chemex'>
        <img src='/images/chemex_icon.png' alt="chemex"/>
        <h3>Chemex</h3>
        <p>Drip</p>
        <p>Medium Grind</p>
        <p>2-3 min</p>
    </Link></div>
    
);

export default ChemexPage;
export {ChemexBox};