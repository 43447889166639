import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './brew.styles.scss'
import Layout from '../../layout/layout';

class ComingSoonPage extends Component{
    render(){
        return(
            <Layout>
            </Layout>
        )
    }
}

const ComingSoonBox = () => (
    <div className="method-box"><div className="method-box-link">
        <h3>COMING SOON</h3>
        <p>New Brewing Guides Coming Soon!</p>
    </div>
    </div>
);

export default ComingSoonPage;
export {ComingSoonBox};