import React, { Fragment } from 'react'
import {  Link } from 'react-router-dom'
import './Header.styles.scss'
import PromoBar from './OfferCountdown'
import './OfferCountdown.scss'

import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { logout } from '../../actions/userActions'
import MobileNav from './MobileNav';
//import Search from './search'

const Header = () => {

    const alert = useAlert();
    const dispatch = useDispatch();

    const { user, loading } = useSelector(state => state.auth)
    const { cartItems } = useSelector(state => state.cart)

    const logoutHandler = () => {
        dispatch(logout());
        alert.success('Logged out successfully.')
    }

    const now = new Date(),
    dynEnd = now.getTime() + 1000 * 60 * 60 * 12;
    
    return (
        <Fragment>

            {/* <PromoBar
            message={"BLACK FRIDAY SALE! SAVE $4 ON ANY ORDER "}
            btnLabel={"SHOP NOW"}
            link={"https://www.brewingengineers.io/shop"}
            // endTime={"THRU NOV 30"}
            endTime={dynEnd}
            /> */}

            <MobileNav className="mobile-nav"/>
            <div className="navbar">
                <div className="col-12 col-md-3">
                    <div className="navbar-brand">
                        <Link className="mobile-logo" to='/'>         
                            <img src="/images/logo.png" alt="logo"/> Beta
                        </Link>
                    </div>
                </div>

                

                {/* <div className="col-12 col-md-3 mt-4 mt-md-0 text-center">
                    <Route render={({history}) => <Search history={history} />} />
                </div> */}

                <ul>
                    <li>
                        <Link  to='/'>
                            HOME
                        </Link>
                    </li>
                    <li>
                        <Link to='/shop'>
                            SHOP
                        </Link>
                    </li>
                    <li>
                        <Link className='nav-pages-link' to='/recipes'>
                                RECIPES
                        </Link>
                    </li>
                    <li>
                        <Link to='/brew'>
                            BREW
                        </Link>
                    </li>
                    <li>
                        <Link to='/about'>
                            ABOUT
                        </Link>
                    </li>
                </ul>

                <div className="col-12 col-md-3  text-center nav-account">

                    {user ? (
                        <div>
                            {user && user.role === 'admin' && (
                                    <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
                            )}
                        <div className=" d-inline">
                            <Link to="/profiletabs" className="btn" type="button" aria-haspopup="true">

                                <figure className="avatar avatar-nav">
                                    <img
                                        src={user.avatar && user.avatar.url}
                                        alt={user && user.name}
                                        className="rounded-circle"
                                    />
                                </figure>
                                <span>{user && user.name}</span>
                            </Link>
                        </div>
                        
                        <Link id="login_btn" to="/" onClick={logoutHandler}>
                            Logout
                        </Link>
                        
                    </div>

                    ) : !loading && <Link to="/login" className=" ml-4" id="login_btn">Login</Link>}



                    <Link to='/cart' style={{textDecoration:'none'}} className='cart-container'>
                        {/* <span id="cart" className="ml-3">CART</span> */}
                        <img src="/images/shopping-cart.png" alt=""/>
                        <span className="cart-count" id="cart_count">{cartItems.length}</span>
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

export default Header;