import React, { Fragment, useState, useEffect } from 'react'

import MetaData from '../layout/MetaData'
//import Sidebar from './Sidebar'
import './UpdateRecipe.scss'
import Modal from 'react-modal';

import { Button} from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { updateRecipe, getRecipeDetails, clearErrors, deleteRecipe } from '../../actions/recipeActions'
import { UPDATE_RECIPE_RESET, DELETE_RECIPE_RESET } from '../../constants/recipeConstants'
import Loading from '../pages/loading/loading'
//import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import useToggle from "./useToggle.ts";
import Select, {components} from 'react-select';

const UpdateRecipe = ({ match, history }) => {

	const options = [
		"AeroPress",
		"Chemex",
		"V60",
		"Turkish Coffee",
		"Siphon",
		"Cold Brew",
        "French Press",
        "Coffee Maker"
    ]

    const [isOn, toggleIsOn] = useToggle();
    const [isOnTwo, toggleIsOnTwo] = useToggle();
    const [isOnThree, toggleIsOnThree] = useToggle();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showTwo, setShowTwo] = useState(false);

    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);

    const [name, setName] = useState('');
    const [coffee, setCoffee] = useState(0);
    const [description, setDescription] = useState('');
    const [grind, setGrind] = useState('');
    const [water, setWater] = useState(0);
    const [ratio, setRatio] = useState('');
    const [coffeeType, setCoffeeType] = useState('');
    const [roast, setRoast] = useState('');
    const [duration, setDuration] = useState(0);
    const [temperature, setTemperature] = useState(0);
    const [method, setMethod] = useState([]);
    const [steps, setSteps] = useState([]);
    const [customMethodField, setCustomMethodField] = useState('');

    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, recipe } = useSelector(state => state.recipeDetails)
    const { loading, error: deleteError, updateError, isUpdated,isDeleted } = useSelector(state => state.recipe);

    const recipeId = match.params.id;

    const [ratioOne, setRatioOne] = useState(recipe && recipe.ratio.split(':')[0]);
    const [ratioTwo, setRatioTwo] = useState(recipe && recipe.ratio.split(':')[1]);

    //dragable steps process=====================
    const [items, setItems] = useState(recipe && recipe.steps);

    //add item to array - recipe steps
    const addItem = () => {
        setItems([ ...items,{
            id: parseInt((items.length)),
            name: 'Custom Step ' + (items.length + 1),
            order: parseInt((items.length )),
            note: '',
            duration: 0,
            image:[
                {
                    url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1663213792/steps/question_ff3xfm.png"
                },
                {
                    public_id: "steps/question_ff3xfm",
                }
            ]
        } ]);
    }

    const handleDragEnd = (result) => {
		if (!result.destination) return;
        const itemss = Array.from(items);
        const [reorderedItem] = itemss.splice(result.source.index, 1);
        itemss.splice(result.destination.index, 0, reorderedItem );
        
        itemss.forEach((element, index) => {
            itemss[index] = { ...element,  order: + index}
        });
        setItems(itemss);
   
	};

    //update steps array
    const updateFieldChangedName = index => e => {
        const newItems = items.map(item => {
            if (item.order == index) {
                   return { ...item,  name: e.target.value}
                }
               
                return item;
            });
                setItems(newItems);
    }
   
    const updateFieldChangedNote = index => e => {
        const newItems = items.map(item => {
               if (item.order == index) {
                   return { ...item,  note: e.target.value}
                 }
                return item;
            });
                setItems(newItems);
    }
    const updateFieldChangedDuration = index => e => {
        const newItems = items.map(item => {
               if (item.order == index) {
                   return { ...item,  duration: e.target.value}
                 }
                return item;
            });
                setItems(newItems);
    }

    const removeItem = (index) => {
        setItems([
                ...items.slice(0, index),
                ...items.slice(index + 1)
            ]);
    }

    const deleteRecipeHandler = (id) => {
        dispatch(deleteRecipe(id))
    }

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set('name', name);
        formData.set('coffee', coffee);
        formData.set('description', description);
        formData.set('grind', grind);
        formData.set('water', water);
        formData.set('ratio', ratioOne + ':' + ratioTwo);
        formData.set('temperature', temperature);
        formData.set('roast', roast);
        formData.set('coffeeType', coffeeType);
        formData.set('method', method);
        formData.set('duration', duration);
        formData.set('customMethodField', customMethodField);
        formData.set('steps', (JSON.stringify(items)));

        dispatch(updateRecipe(recipe._id, formData))
    }

    useEffect(() => {
        if (recipe && recipe._id !== recipeId) {
            dispatch(getRecipeDetails(recipeId));
        } else {
            setName(recipe.name);
            setGrind(recipe.grind);
            setDescription(recipe.description);
            setCoffee(recipe.coffee);
            setDuration(recipe.duration)
            // setRatio(recipe.ratio);
            setMethod(recipe.method);
            setWater(recipe.water)
            setTemperature(recipe.temperature);
            setCoffeeType(recipe.coffeeType);
            setRoast(recipe.roast);
            setCustomMethodField(recipe.customMethodField);
            //setItems(recipe.steps);
        }

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }

   //     if (deleteError) {
   //         alert.error(deleteError);
   //         dispatch(clearErrors())
   //     }

       if (isDeleted) {
           alert.success('Recipe deleted successfully');
           history.push('/recipes');
           dispatch({ type: DELETE_RECIPE_RESET })
       }

        if (updateError) {
            alert.error(updateError);
            dispatch(clearErrors())
        }


        if (isUpdated) {
            history.push('/recipes');
            alert.success('Recipe updated successfully');
            dispatch({ type: UPDATE_RECIPE_RESET })
        }

    }, [dispatch, alert, error, isUpdated, history, updateError, recipe, recipeId, , isDeleted,deleteError])

    function fancyTimeFormat(duration){ 
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }


    //testing out more dynamic modal
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null);

    const expandModal = (steps) => {
        setSelectedProject(steps);
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setSelectedProject(null);
        setModalIsOpen(false);
    }

    // dynamic modal
    const [modalIsOpenTwo, setModalIsOpenTwo] = useState(false)
    //const [selectedProjectTwo, setSelectedProjectTwo] = useState(null);

    const expandModalTwo = () => {
        //setSelectedProjectTwo();
        setModalIsOpenTwo(true);
    }

    const closeModalTwo = () => {
        // setSelectedProjectTwo(null);
        setModalIsOpenTwo(false);
    }
console.log(items)

    // //3 dot dropdown
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <i class="fas fa-ellipsis-v"></i>
          {children}
      
        </a>
      ));

    return (
        <Fragment>
        <MetaData title={'Update Recipe'} />
        {loading ? <Loading/> : (
            <div className="new-recipe-full">
                <div className="new-recipe-page">
                    <div className="go-back-lab">
                        <div className="back-link">
                            <Link to="/recipes" className="back-link-text"><i class="fa fa-arrow-left"></i> Go Back To Recipes</Link>
                        </div>
                        <div className='header-recipe-logo'>
                            <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
                        </div>
                    </div>
                        <form className="recipe-full-box" onSubmit={submitHandler} encType='multipart/form-data'>
                        <div className="recipe-title">
                            {/* <div className="recipe-title-row"> */}
                                <div>Recipe Title: <input value={name} onChange={(e) => setName(e.target.value)} /></div>                
                                <div>Duration: {fancyTimeFormat(recipe.steps && recipe.steps.reduce((totalDuration, durations) => totalDuration + durations.duration, 0) )}</div>
                                <div>Ratio: <input className="ratio-width" type="text" maxlength="3" value={ratioOne}  onChange={(e) => setRatioOne(e.target.value)}/> : <input type="text" className="ratio-width" value={ratioTwo} maxlength="3" onChange={(e) => setRatioTwo(e.target.value)}/></div>
                        
                                <div className="row-flex-edit">
                                    <button
                                        id="login_button"
                                        type="submit"
                                        className="btn btn-block py-3 text-light"
                                        disabled={loading ? true : false}
                                        >
                                    Update
                                    </button>

                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                        <Dropdown.Header>Options</Dropdown.Header>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <p onClick={() => deleteRecipeHandler(recipe._id)}>   
                                                Delete
                                            </p>
                                        </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                {/* </div> */}
                            </div>
                                {/* </Link> */}
                                {/* <div>
                                    <button
                                        id="login_button"
                                        type="submit"
                                        className="btn btn-block py-3"
                                    >
                                        CREATE
                                    </button>
                                </div> */}
                            </div>
                            <div className="recipe-full-boxes">
                                <div className="recipe-half-box">
                                    <p><b>RECIPE DETAILS</b></p>
                                    <div className="recipe-half-box-left-top">
                                        <div className="d-flex flex-column justify-content-start align-items-center">
                                            <p>RECIPE DETAILS</p>
                                            <div>
                                                <div>
                                                    <label htmlFor="size_field">Select Method: </label>
                                                    <select id="size_field" value={method} onChange={(e) => setMethod(e.target.value)} >
                                                        {options.map(method => (
                                                            <option key={method} value={method} >{method}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="coffee_type">Coffee Type: </label>
                                                    <input id='coffee_type' value={coffeeType} onChange={(e) => setCoffeeType(e.target.value)} id="title" />
                                                </div>
                                            </div>
                                                

                                        </div>
                                        <div className="recipe-half-box-left-top-right">
                                            <div  style={{width: '100%',height: '70%'}}>
                                                Description:
                                                <textarea style={{width: '100%',height: '100%'}}
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    //className="inputText"
                                                    id="description">
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="recipe-half-box-left-bottom">
                                        <div className="recipe-half-box-left">    
                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="coffee_field">COFFEE </label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>0</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>{isOn ? "1": "25"}</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>{isOn ? "2": "50"}</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="coffee_field"
                                                    className="form-control"
                                                    value={coffee}
                                                    onChange={(e) => setCoffee(e.target.value)}
                                                    min="0"
                                                    max={isOn ? "57": "50"}
                                                />
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <p className="">{isOn ? (coffee * 0.03527).toFixed(2) + ' oz': coffee +' g'}</p>
                                                    <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOn}>
                                                        Unit
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="roast_field">ROAST</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Li</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Med</span>
                                                    <span className="p-0  mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Dark</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="roast_field"
                                                    className="form-control"
                                                    value={roast}
                                                    onChange={(e) => setRoast(e.target.value)}
                                                    list="tickmarks"
                                                    steps="5"
                                                />
                                                <datalist id="tickmarks">
                                                    <option value="Light" label="Light"></option>
                                                    <option value="Medium Light"></option>
                                                    <option value="Medium"></option>
                                                    <option value="Medium Dark"></option>
                                                    <option value="Dark" label="Dark"></option>
                                                </datalist>
                                                <p>{roast}</p>
                                            </div>

                                            <div className="form-group mb-0">
                                                <label className="mb-0"  htmlFor="grind_field">GRIND</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <p className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>1</p>
                                                    <p className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>5</p>
                                                    <p className="p-0  mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>10</p>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <p className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</p>
                                                    <p className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</p>
                                                    <p className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</p>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="grind_field"
                                                    className="form-control"
                                                    value={grind}
                                                    list="tickmarksTwo"
                                                    onChange={(e) => setGrind(e.target.value)}
                                                    step="10"
                                                />
                                                <datalist id="tickmarksTwo">
                                                    <option value="Extra Fine" label="1"></option>
                                                    <option value="Extra Fine"></option>
                                                    <option value="Fine"></option>
                                                    <option value="Fine"></option>
                                                    <option value="Medium" label="5"></option>
                                                    <option value="Medium"></option>
                                                    <option value="Coarse"></option>
                                                    <option value="Coarse"></option>
                                                    <option value="Extra Coarse"></option>
                                                    <option value="Extra Coarse" label="10"></option>
                                                </datalist>
                                                <p>{grind}</p>
                                            </div>
                                        
                                        </div>
                                        <div className="recipe-half-box-left">

                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="water_field">WATER</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>0</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>300</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>600</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="water_field"
                                                    className="form-control"
                                                    value={water}
                                                    onChange={(e) => setWater(e.target.value)}
                                                    min="0"
                                                    max="600"
                                                />
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <p className="">{isOnTwo ? water + ' ml' : water +' g'}</p>
                                                    <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOnTwo}>
                                                        Unit
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="temp_field">TEMPERATURE</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>0</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>150</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>300</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="temp_field"
                                                    className="form-control"
                                                    value={temperature}
                                                    onChange={(e) => setTemperature(e.target.value)}
                                                    min="0"
                                                    max="300"
                                                />
                                                 <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <p className="">{isOnThree ? ((5/9) * (temperature - 32)).toFixed(3) + ' c': temperature +' f'}</p>
                                                    <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOnThree}>
                                                        Unit
                                                    </p>
                                                </div>
                                            </div>

                                           {recipe.method == "AeroPress" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">ORIENTATION</label>
                                                        <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Standard</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Inverted</span>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                        <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        id="ratio_field"
                                                        className="form-control"
                                                        value={customMethodField}
                                                        onChange={(e) => setCustomMethodField(e.target.value)}
                                                        step="1"
                                                        min="1"
                                                        max="2"
                                                    />
                                                    {/* <p>{recipe.CustomMethodField}</p> */}
                                                </div>
                                            }

                                            {recipe.method == "Chemex" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">CUP SIZE</label>
                                                        <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>3 cups</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>6 cups</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>8 cups</span>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                        <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        id="ratio_field"
                                                        className="form-control"
                                                        value={customMethodField}
                                                        onChange={(e) => setCustomMethodField(e.target.value)}
                                                        min="1"
                                                        max="3"
                                                    />
                                                    {/* <p>{recipe.CustomMethodField}</p> */}
                                                </div>
                                            }
                                            {recipe.method == "V60" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">CUP SIZE</label>
                                                        <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>1</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>2</span>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                        <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        id="ratio_field"
                                                        className="form-control"
                                                        value={recipe.CustomMethodField}
                                                        step="1"
                                                        min="1"
                                                        max="2"
                                                    />
                                                    {/* <p>{recipe.CustomMethodField}</p> */}
                                                </div>
                                            }
                                        </div>
                                    </div>
               
                                </div>
                                <div className='recipe-half-box-steps'>
                                <p>RECIPE STEPS</p>
                                <div style={{overflow: 'auto', height: 'inherit', display: 'block', }}>   
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="list">
                                    {(provided, snapshot) => (
                                        <div
                                        className="steps-box"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        >
                                        {items && items.map((item, index) => (
                                            <Draggable className="steps-box" draggableId={item.order.toString()} index={index} key={item.order}>
                                            {(provided, snapshot) => (
                                                <div
                                                //className="card"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="step-box"
                                                >
                                                            <div className="d-flex flex-row justify-content-between align-items-center w-100 pl-3 pr-3">
                                                                <p>{item.order}</p>
                                                                <i style={{color: '#c42f2f'}} id="delete_step" className="fa fa-trash p-0" onClick={() => removeItem(index)} ></i>
                                                            </div>
                                                            <div className="step-box-section"><p>Name: <input type='text' value={item.name} onChange={updateFieldChangedName(index)}/></p></div>
                                                            {/* <div className="step-box-section"><p>Order: <input type='number' value={index} onChange={updateFieldChangedOrder(index)}/></p></div> */}
                                                            <div className="step-box-section"><p>Duration: <input type='number' value={item.duration} onChange={updateFieldChangedDuration(index)}/></p></div>
                                                            <div className="step-box-section pb-2"><p>Notes: <input type='text' value={item.note} onChange={updateFieldChangedNote(index)}/></p></div>
                                                        </div>
                                                    
                                                    )}
                                                </Draggable>                                              
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
            
                                </div>
                                <div className="steps-box-add">
                                    <span onClick={() => addItem()} >New Step</span>
                                </div>
                            </div>
                            </div>

                        </form>
                </div>
            </div>
        )}
        </Fragment>
    )
}

export default UpdateRecipe;