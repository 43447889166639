import React from "react";
import { ReactComponent as Play } from "../../../images/play.svg";
import { ReactComponent as Pause } from "../../../images/pause.svg";
import { ReactComponent as Next } from "../../../images/next.svg";
import { ReactComponent as Prev } from "../../../images/prev.svg";

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick
}) => (
  <div className="audio-controls">
    <button
      type="button"
      className="prev"
      aria-label="Previous"
      onClick={onPrevClick}
    >

      {/* <Prev /> */}
      <i class="far fa-arrow-alt-circle-left"></i>
    </button>
    {isPlaying ? (
      <button
        type="button"
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        {/* <Pause /> */}
        <i class="far fa-pause-circle"></i>
      </button>
    ) : (
      <button
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        {/* <Play /> */}
        <i class="far fa-play-circle"></i>
      </button>
    )}
    <button
      type="button"
      className="next"
      aria-label="Next"
      onClick={onNextClick}
    >
      {/* <Next /> */}
      <i class="far fa-arrow-alt-circle-right"></i>
    </button>
  </div>
);

export default AudioControls;
