import React, { Fragment } from 'react'
import Layout from './components/layout/layout'
import { Link } from 'react-router-dom';

import MetaData from './components/layout/MetaData'
import './components/cart/Checkout.scss'

const PrivatePolicy = () => {

    return (
        <Layout>
        <Fragment>

            <MetaData title={'Shipping Info'} />

            <div className="go-back">
                <Link to="/" className="back-link-checkout"><i class="fa fa-arrow-left"></i> Go Back To Home Page</Link>
            </div>

            <div className='full-page'> 
            <div className='about-form'>
                <h2 className="mb-3 pl-5 pr-5">SHIPPING ORDERS</h2>
                <p className="pl-5 pr-5">
                    All orders placed by noon EST on Tuesday and 
                    Thursday will be fulfilled same-day. All orders 
                    after that time will be fulfilled on the following 
                    roast day. This allows us to ship a majority of 
                    orders within 48 hours.
                </p>
                <p className="pl-5 pr-5">
                    Please note: As a small business, we depend on 
                    the U.S. Postal Service to deliver orders 
                    placed through our online store. (We do not
                    have a global fleet of delivery trucks and 
                    fulfillment centers like Amazon 🙃 .) Once
                    we drop packages at the post office, delivery
                    is out of our hands. Due to unprecedented 
                    demand on USPS through the coronavirus pandemic, 
                    some shipments have seen unusual delays, though 
                    we hope that is not the case with yours.
                </p>
                <p className="pl-5 pr-5">
                    Thank you for your business.
                </p>
            </div>
            </div>

        </Fragment>
    </Layout>
    )
}

export default PrivatePolicy