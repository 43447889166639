import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import MetaData from '../layout/MetaData'
//import Loader from '../layout/Loader'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetails, clearErrors } from '../../actions/orderActions'

const OrderDetails = ({ match }) => {

    const alert = useAlert();
    const dispatch = useDispatch();

    const { loading, error, order = {} } = useSelector(state => state.orderDetails)
    const { shippingInfo, orderItems, paymentInfo, user, totalPrice, orderStatus,usedCoupon } = order

    useEffect(() => {
        dispatch(getOrderDetails(match.params.id));

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, alert, error, match.params.id])

    const shippingDetails = shippingInfo && `${shippingInfo.address}, United States`

    const isPaid = paymentInfo && paymentInfo.status === 'succeeded' ? true : false
    const isCoupon = order.usedCoupon && order.usedCoupon === true ? "Yes" : "No"

    return (
        <Fragment>
            <MetaData title={'ORDER DETAILS'} />

            {loading ? <h1>LOADING...</h1> : (
                <Fragment>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="go-back">
                            <Link to="/profiletabs" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Orders</Link>
                        </div>
                        <div className="col-12 col-lg-8 mt-5 order-details">
                        <div className='about-page'>

                            <h5 className="my-5">Order # {order._id}</h5>

                            <h4 className="mb-4">Shipping Info</h4>
                            <p><b>Name:</b> {user && user.name}</p>
                            <p><b>Phone:</b> {shippingInfo && shippingInfo.phoneNo}</p>
                            <p className="mb-4"><b>Address:</b>{shippingDetails}</p>
                            <p><b>Amount:</b> ${totalPrice}</p>
                            <p><b>Used Coupon:</b> {isCoupon}</p>

                            <hr />

                            <h4 className="my-4">Payment</h4>
                            <p className={isPaid ? "greenColor" : "redColor"}><b>{isPaid ? "PAID" : "NOT PAID"}</b></p>

                            <h4 className="my-4">created At</h4>
                            <p>{order.createdAt}</p>

                            {/* <h4 className="my-4">Delivered At</h4>
                            <p>{order.deliveredAt}</p> */}


                            <h4 className="my-4">Order Status:</h4>
                            <p className={order.orderStatus && String(order.orderStatus).includes('Delivered') ? "greenColor" : "redColor"} ><b>{orderStatus}</b></p>


                            <h4 className="my-4">Order Items:</h4>

                            <hr />
                            <div className="cart-item my-1">
                                {orderItems && orderItems.map(item => (
                                    <div key={item.product} className="row my-5">
                                        <div className="col-4 col-lg-2">
                                            <img src={item.image} alt={item.name} height="45" width="65" />
                                        </div>

                                        <div className="col-5 col-lg-5">
                                            <Link to={`/products/${item.product}`}>{item.name}</Link>
                                        </div>


                                        <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                                            <p>${item.price}</p>
                                        </div>

                                        <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                                            <p>{item.quantity} Piece(s)</p>
                                        </div>

                                        <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                                            <p> Size: {item.size}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <hr />
                        </div>
                        </div>
                        
                    </div>
                </Fragment>
            )}

        </Fragment>
    )
}

export default OrderDetails