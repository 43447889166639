import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../pages/loading/loading'
import MetaData from '../layout/MetaData'

const Subscriptions = () => {

    const { user, loading } = useSelector(state => state.auth)

    return (
        <Fragment>
            {loading ? <Loading/> : (
                <Fragment>
                    <MetaData title={'YOUR SUBSCRIPTION'} />

                    {/* <h4  className="mt-5 ml-5">Subscriptions</h4> */}
                    <div className="row justify-content-around mt-5 user-info m-0 profile-page">
                        {/* <div className="col-8 col-md-3 p-0">
                            <figure className='avatar avatar-profile'>
                                <img className="rounded-circle img-fluid" src={user.avatar.url} alt={user.name} />
                            </figure>
                            <Link to="/me/update" id="edit_profile" id="login_btn" className="btn-block my-2">
                                EDIT PROFILE
                            </Link>
                        </div> */}

                        <div className="col-8 col-md-5 p-0">
                        {/* {user.subscriptions.map((item, index) => ( */}
                            <div>
                                {/* {item && item.active == true && 
                                    <div>
                                        <h4>Subscription Name</h4>
                                        <p>{item.plan}</p>

                                        <h4>Active</h4>
                                        <p>{item.active? 'True' : 'False'}</p>

                                        <h4>Product</h4>
                                        <p>{item.product}</p>
                                    </div>
                                } */}
                                {/* {item && item.active == false &&  */}
                                    <div>
                                        <h4>You have no active subscriptions.</h4> 
                                    </div>
                                {/* } */}
                            </div>
                        {/* ))} */}
                        
                            {/* {user.role !== 'admin' && (
                                <Link to="/orders/me" id="login_btn" className="btn-block mt-5">
                                    MY ORDERS
                                </Link>
                            )}

                            <Link to="/password/update" id="login_btn" className="btn-block mt-3 mb-4">
                                CHANGE PASSWORD
                            </Link> */}
                        
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Subscriptions