import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import Loading from '../pages/loading/loading'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { register, clearErrors } from '../../actions/userActions'


const Register = ({ history }) => {

    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
    })

    const { name, email, password } = user;

    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('/images/default_avatar.jpg')
    const [checked, setChecked] = useState(true)
    const handleClick = () => setChecked(!checked)

    const alert = useAlert();
    const dispatch = useDispatch();

    const { isAuthenticated, error, loading } = useSelector(state => state.auth);

    useEffect(() => {

        if (isAuthenticated) {
            history.push('/')
        }

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

    }, [dispatch, alert, isAuthenticated, error, history])

    const submitHandler = (e) => {
        e.preventDefault();

//         const formData = new FormData();
//         formData.set('name', name);
//         formData.set('email', email);
//         formData.set('password', password);
//         formData.set('avatar', avatar);
// console.log(formData)
//         dispatch(register(formData))

        const formData = new FormData();
        formData.set('name', name);
        formData.set('email', email);
        formData.set('password', password);
        formData.set('avatar', avatar);
        formData.set('newsletter', checked);

        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var json = object
console.log(json)
        dispatch(register(json))
    

    }

    const onChange = e => {
        if (e.target.name === 'avatar') {

            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                    setAvatar(reader.result)
                }
            }

            reader.readAsDataURL(e.target.files[0])

        } else {
            setUser({ ...user, [e.target.name]: e.target.value })
        }
    }

    return (
        <Fragment>

            <MetaData title={'REGISTER'} />
            {loading ? <Loading/> : ( 
            <div className="login-page">
                    <div className="go-back mt-2">
                        <Link to="/" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Homepage</Link>
                    </div>
                    <div className="login">
                    <form className="login-form" onSubmit={submitHandler} enctype='application/json'>
                    {/* <form className="login-form" onSubmit={submitHandler} enctype='multipart/form-data'> */}
                        <h1 className="mt-4">SIGN UP</h1>

                        <div className="group">
                            {/* <label htmlFor="email_field">Name</label> */}
                            <input
                                type="name"
                                id="name_field"
                                className="login-input"
                                name='name'
                                value={name}
                                onChange={onChange}
                                required
                                placeholder='FULL NAME'
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <img className="label-login" src='/images/images.png' alt=""/>
                        </div>

                        <div className="group">
                            {/* <label htmlFor="email_field">Email</label> */}
                            <input
                                type="email"
                                id="email_field"
                                className="login-input"
                                name='email'
                                value={email}
                                onChange={onChange}
                                required
                                placeholder="EMAIL"
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <img className="label-login" src='/images/email.png' alt=""/>
                        </div>

                        <div className="group">
                            {/* <label htmlFor="password_field">Password</label> */}
                            <input
                                type="password"
                                id="password_field"
                                className="login-input"
                                name='password'
                                value={password}
                                onChange={onChange}
                                required
                                placeholder="PASSWORD"
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <img className="label-login" src='/images/locked-outline.png' alt=""/>
                        </div>

                        <div className='form-group'>
                            <label htmlFor='avatar_upload'>Avatar</label>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <figure className='avatar mr-3 item-rtl'>
                                        <img
                                            src={avatarPreview}
                                            className='rounded-circle'
                                            alt='Avatar Preview'
                                        />
                                    </figure>
                                </div>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        name='avatar'
                                        className='custom-file-input'
                                        id='customFile'
                                        //accept="iamges/*"
                                        onChange={onChange}
                                        //multiple="multiple"
                                        //accept="image/*"
                                        accept="image/jpeg,image/png,image/webp"
                                        tabIndex="-1"
                                        required
                                        
                                    />
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        Choose Avatar
                                    </label>
                                </div>
                            </div>
                        </div>

                         <div className="d-flex flex-row justify-content-start align-items-center pl-5 pr-5">
                            <p className="m-0 pr-1">Would you like to subscribe to our newsletter</p>
                            <input onClick={handleClick} checked={checked} type="checkbox" />
                        </div>

                        <div className="div_button">
                            <button
                                //id="register_button"
                                type="submit"
                                className="btn btn-block py-3"
                                disabled={loading ? true : false}
                            >
                            REGISTER
                            </button>
                        </div>
                        <p>Already have an account? <Link to='/login'>LOGIN</Link></p>
                    </form>
                </div>
            </div>
        )}
        </Fragment>
    )
}

export default Register