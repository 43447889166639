import React from 'react';
import MetaData from '../../layout/MetaData';
import Layout from '../../layout/layout';
import { ChemexBox } from '../brew/chemex'
import { AeroPressBox } from './aeropress';
import { EspressoBox } from './espresso';
import { ComingSoonBox } from './brew-coming-soon';
import { V60Box } from './v60';

const BrewPage = () => {
    return(
        <Layout>
            <MetaData title={"BREWING GUIDES"} />
            <div className='brew-page'>
                <div className='brew-box'>
                    <ChemexBox />
                    <AeroPressBox />
                    <EspressoBox />
                    <V60Box />
                    <ComingSoonBox/>
                </div>
            </div>
        </Layout>
    )
}

export default BrewPage;