import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './UpdateRecipe.scss'

import { NEW_REVIEW_RESET } from '../../constants/productConstants';


import {useDispatch, useSelector} from 'react-redux';
import { getRecipeDetails, clearErrors } from '../../actions/recipeActions';
import { useAlert } from 'react-alert';
import MetaData from '../layout/MetaData';
import Loading from '../pages/loading/loading'


const recipeSteps = [
    {
        id: 'swirl',
        name: 'SWIRL',
        order: 1,
        description: 'testing one',
        duration: 23
    },
    {
        id: 'wait_time',
        name: 'WAIT TIME',
        order: 2,
        description: 'testing one',
        duration: 23
    },
]


const RecipeDetails = ({ match }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showTwo, setShowTwo] = useState(false);

    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);


    const dispatch = useDispatch();
    const alert = useAlert();

    const {loading, error, recipe} = useSelector(state => state.recipeDetails)
    const { user } = useSelector(state => state.auth)
    const { error: reviewError, success } = useSelector(state => state.newReview)

    useEffect(() => {
        dispatch(getRecipeDetails(match.params.id))

        if(error){
            alert.error(error);
            dispatch(clearErrors())
        }
        
        if (reviewError) {
            alert.error(reviewError);
            dispatch(clearErrors())
        }

        // if (success) {
        //     alert.success('Reivew posted successfully')
        //     dispatch({ type: NEW_REVIEW_RESET })
        // }

    }, [dispatch, alert, error, reviewError, match.params.id, success])

    const addToCart = () => {
        //dispatch(addItemToCart(match.params.id, quantity,size));
        //dispatch(addItemToCart(match.params.id, size));

        alert.success('Item Added to Cart')


        //const formData = new FormData();

        //formData.append('_id',match.params.id);
        //formData.set('quantity',quantity);
        //formData.set('size', size);
        //alert.success('Item Added to Cart')

        //dispatch(addItemToCart(formData));
    }

    const [characters, updateCharacters] = useState(recipeSteps);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
    
        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        updateCharacters(items);
    }
    
    const addItem = () => {
        // const newItem = {
        //     id: 'custom_step',
        //     name: 'CUSTOM STEP',
        //     order: 3,
        //     description: 'testing 4',
        //     duration: 23
        // }
        updateCharacters([ ...characters,{
            id: 'custom_step' + (characters.length + 1),
            name: 'CUSTOM STEP',
            order: 3,
            description: 'testing 4',
            duration: 23
        } ]);
    }

    function handleSubmit(event) {
        event.preventDefault();
      }
    function addStepModal() {
        handleCloseTwo();
        addItem();
    }
    console.log(characters)

    //==========timer

        const [renderedStreamDuration, setRenderedStreamDuration] = useState(
            "00:2:00"
          ),
          streamDuration = useRef(120),
          previousTime = useRef(120),
          requestAnimationFrameId = useRef(null),
          [isStartTimer, setIsStartTimer] = useState(false),
          [isStopTimer, setIsStopTimer] = useState(false),
          [isPauseTimer, setIsPauseTimer] = useState(false),
          [isResumeTimer, setIsResumeTimer] = useState(false),
          isStartBtnDisabled = isPauseTimer || isResumeTimer || isStartTimer,
          isStopBtnDisabled = !(isPauseTimer || isResumeTimer || isStartTimer),
          isPauseBtnDisabled = !(isStartTimer || (!isStartTimer && isResumeTimer)),
          isResumeBtnDisabled = !isPauseTimer;
      
        const updateTimer = useCallback(() => {
          let now = performance.now();
          let dt = now - previousTime.current;
      
          if (dt >= 1000) {
            streamDuration.current = streamDuration.current - Math.round(dt / 1000);     
            const formattedStreamDuration = new Date(streamDuration.current * 1000)
              .toISOString()
              .substr(11, 8);
            setRenderedStreamDuration(formattedStreamDuration);
            previousTime.current = now;
          }
          requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
        }, []);

    
      
        const startTimer = useCallback(() => {
          previousTime.current = performance.now();
          requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
        }, [updateTimer]);
      
        useEffect(() => {
          if (isStartTimer && !isStopTimer) {
            startTimer();
          }

          if (isStopTimer && !isStartTimer) {
            streamDuration.current = 120;
            cancelAnimationFrame(requestAnimationFrameId.current);
            setRenderedStreamDuration("0:02:00");
          }
        }, [isStartTimer, isStopTimer, startTimer]);
      
        const startHandler = () => {
          setIsStartTimer(true);
          setIsStopTimer(false);
        };
      
        const stopHandler = () => {
          setIsStopTimer(true);
          setIsStartTimer(false);
          setIsPauseTimer(false);
          setIsResumeTimer(false);
        };
      
        const pauseHandler = () => {
          setIsPauseTimer(true);
          setIsStartTimer(false);
          setIsResumeTimer(false);
          cancelAnimationFrame(requestAnimationFrameId.current);
        };
      
        const resumeHandler = () => {
          setIsResumeTimer(true);
          setIsPauseTimer(false);
          startTimer();
        };


    return (
        <Fragment>
        <MetaData title={recipe.name} />
        {loading ? <Loading/> : (
            <div className="new-recipe-full">
                <div className="new-recipe-page">
                    <div className="go-back-lab">
                        <Link to="/recipes" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Recipes</Link>
                        <div className='header-recipe-logo'>
                            <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
                        </div>
                    </div> 
                        <form className="recipe-full-box" encType='multipart/form-data'>
                            <div className="recipe-title">
                                <h5>{recipe.name}</h5>
                                
                                <div>Duration: {recipe.duration}</div>
                                <div> Ratio: {recipe.ratio}</div>
                            
                                {/* <button   
                                        // id="login_button"
                                        type="submit"
                                        //className="btn btn-block py-3"
                                    >
                                        Start Recipe
                                    </button> */}
                                {/* <div>
                                    <button
                                        id="login_button"
                                        type="submit"
                                        className="btn btn-block py-3"
                                    >
                                        CREATE
                                    </button>
                                </div> */}

                                                <div className="timer-display">{renderedStreamDuration}</div>
                                                <div className="buttons-wrapper">
                                                    <button
                                                    onClick={startHandler}
                                                    disabled={isStartBtnDisabled}
                                                    className={`timer-controller-btn ${
                                                        isStartBtnDisabled ? "disabled" : ""
                                                    }`}
                                                    >
                                                    start
                                                    </button>
                                                    <button
                                                    className={`timer-controller-btn danger ${
                                                        isStopBtnDisabled ? "disabled" : ""
                                                    }`}
                                                    disabled={isStopBtnDisabled}
                                                    onClick={stopHandler}
                                                    >
                                                    stop
                                                    </button>
                                                    <button
                                                    className={`timer-controller-btn ${
                                                        isPauseBtnDisabled ? "disabled" : ""
                                                    }`}
                                                    disabled={isPauseBtnDisabled}
                                                    onClick={pauseHandler}
                                                    >
                                                    pause
                                                    </button>
                                                    <button
                                                    className={`timer-controller-btn ${
                                                        isResumeBtnDisabled ? "disabled" : ""
                                                    }`}
                                                    disabled={isResumeBtnDisabled}
                                                    onClick={resumeHandler}
                                                    >
                                                    resume
                                                    </button>
                                                </div>
                                        
                            </div>
                            <div className="recipe-full-boxes">
                                <div className="recipe-half-box">
                                    <p>STEPS SEQUENCE</p>
                                    {recipe.steps && recipe.steps.map((steps) => {
                                    return(
                                    <div>
                                        {/* <img className="recipe-card-img" src={steps.image[0].url} alt={steps.name}/> */}
                                        <p>{recipe.steps[0].name}</p>
                                        <p>{recipe.steps[0].duration}</p>
                                        
                                    </div>
                                   )})}

                                    

                                </div>
                                <div className='recipe-half-box-steps'>
                                    
                                        <p>RECIPE STEPS</p>
                                        {/* <DragDropContext  onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="characters">
                                                {(provided) => (
                                                <div className="steps-box" {...provided.droppableProps} ref={provided.innerRef}>
                                                    {recipe.steps && recipe.steps.map(( steps) => {
                                                        return (
                                                        <Draggable className="steps-box" key={steps.name} >
                                                            {(provided) => (
                                                            
                                                                <div className="step-box" onClick={handleShow} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="step-box-section">
                                                                        <p>{ steps.name }</p>
                                                                        <img className="recipe-card-img" src={steps.image[0].url} alt={steps.name}/>
                                                                    </div>
                                                                    <div className="step-box-section">
                                                                        <p placeholder="notes">{ steps.description }</p>
                                                                    </div>
                                                                    <div className="step-box-section">
                                                                        <p>ORDER: { steps.order }</p>
                                                                        <div>
                                                                            <p type="number" min='0' max='10'></p>01:<p type="number" min='0' max='59' placeholder="seconds">00</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext> */}
            
                                    {/* <div className="steps-box-add" onClick={handleSubmit}>
                                        <button onClick={handleShowTwo} >hello</button>
                                    </div> */}

                                     {/* edit exising recipe step */}
                                     <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                            <Modal.Title>EDIT RECIPE STEP</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div>
                                                    <div className="step-box-section">
                                                        <h2>Swirl</h2>
                                                    </div>
                                                    <div className="step-box-section">
                                                        <textarea placeholder="notes">testing</textarea>
                                                    </div>
                                                    <div className="step-box-section">
                                                        <span>ORDER:<input type='number'/></span>
                                                        <div>
                                                            <p type="number" min='0' max='10'></p>01:<p type="number" min='0' max='59' placeholder="seconds">00</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="primary" onClick={handleClose}>
                                                Save Changes
                                            </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    {/* create new recipe step */}
                                    <Modal show={showTwo} onHide={handleCloseTwo}>
                                            <Modal.Header closeButton>
                                            <Modal.Title>EDIT RECIPE STEP</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div>
                                                    <div className="step-box-section">
                                                        <h2>Swirl</h2>
                                                    </div>
                                                    <div className="step-box-section">
                                                        <textarea placeholder="notes">testing</textarea>
                                                    </div>
                                                    <div className="step-box-section">
                                                        <span>ORDER:<input type='number'/></span>
                                                        <div>
                                                            <p type="number" min='0' max='10'></p>01:<p type="number" min='0' max='59' placeholder="seconds">00</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="primary" onClick={addStepModal} >
                                                CREATE NEW STEP
                                            </Button>
                                            </Modal.Footer>
                                        </Modal>
                                </div>
                            </div>

                        </form>
                </div>
            </div>
        )}
        </Fragment>
    )
}

export default RecipeDetails;