import React, { Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './UpdateRecipe.scss'
import Modal from 'react-modal';
import { DELETE_RECIPE_RESET } from '../../constants/recipeConstants';
import {useDispatch, useSelector} from 'react-redux';
import { getRecipeDetails, clearErrors,deleteRecipe} from '../../actions/recipeActions';
import { useAlert } from 'react-alert';
import MetaData from '../layout/MetaData';
import Loading from '../pages/loading/loading'
import Dropdown from "react-bootstrap/Dropdown";
import useToggle from "./useToggle.ts";

const pourImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319804/steps/rinse_ia1hvu.png"
    },
    {
        public_id: "steps/rinse_ia1hvu"
    }
];
const swirlImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319793/steps/swirl_f5s3mn.png"
    },
    {
        public_id: "steps/swirl_f5s3mn"
    }
];
const customImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1663213792/steps/question_ff3xfm.png"
    },
    {
        public_id: "steps/question_ff3xfm",
    }
];
const enjoyImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1645577261/steps/Group_20_nj2o6q.png"
    },
    {
        public_id: "steps/Group_20_nj2o6q"
    }
];
const bloomImage =[
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1637880877/steps/Screen_Shot_2021-11-25_at_5.50.59_PM_mdbmae.png"
    },
    {
        public_id: "steps/Screen_Shot_2021-11-25_at_5.50.59_PM_mdbmae"
    }
];
const waitImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319762/steps/wait_nq1gra.png"
    },
    {
        public_id: "steps/wait_nq1gra"
    }
]
const rinseImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1631319804/steps/rinse_ia1hvu.png"
    },
    {
        public_id: "steps/rinse_ia1hvu"
    }
];
const invertImage = [
    {
        url:"https://res.cloudinary.com/dcrrczawj/image/upload/v1664331507/steps/Group_8_vuftls.png"
    },
    {
        public_id: "steps/Group_8_vuftls"
    }
];


const initialState = {
    likes: 100,
    dislikes: 5
  }
  
  const appReducer = (state, action) => {
    switch(action.type) {
      case 'HANDLE_LIKE':
        return {
          ...state,
          likes: state.likes + action.payload
        }
      case 'HANDLE_DISLIKE':
        return {
          ...state,
          dislikes: state.dislikes + action.payload
        }
      default:
        return state
    }
  }


const RecipeDetails = ({ match,history }) => {


    const [isOn, toggleIsOn] = useToggle();
    const [isOnTwo, toggleIsOnTwo] = useToggle();
    const [isOnThree, toggleIsOnThree] = useToggle();
    

    const dispatch = useDispatch();
    const alert = useAlert();

    const {loading, error,error: deleteError, recipe,isDeleted,isUpdated} = useSelector(state => state.recipeDetails)
    const { user } = useSelector(state => state.auth)
   
    useEffect(() => {
        dispatch(getRecipeDetails(match.params.id))

        if(error){
            alert.error(error);
            dispatch(clearErrors())
        }
        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors())
        }
        if (isDeleted) {
            alert.success('Recipe deleted successfully');
            //history.push('/');
            window.location.reload(false);
            dispatch({ type: DELETE_RECIPE_RESET })
        }
        // if (isDeleted) {
        //     alert.success('Product deleted successfully');
        //     history.push('/recipes');
        //     dispatch({ type: DELETE_RECIPE_RESET })
        // }
    }, [dispatch, alert,history, error, match.params.id,isUpdated, isDeleted,deleteError])

    const deleteRecipeHandler = (id) => {
        dispatch(deleteRecipe(id))
    }
    
    function fancyTimeFormat(duration){   
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }
    
    const arr = recipe && recipe.steps;
    // recOne.push(rec)
    
    // const durationTotal = rec.reduce((totalDuration, durations) => totalDuration + durations.duration, 0); 
    
    //  const totalz2 = () => {
    //     var recOne = []
    //     var rec = recipe.steps && recipe.steps;
    //     recOne.push(rec)
    //     // var rec = recipe && recipe.steps;
    //     var total2 = 0;
    //     for (var i = 0; i < recOne.length; i++) {
    //       total2 += recOne[i].duration;
    //     }
    //     return total2;
    // };
    // console.log(total2)
    //var arr = [{ 'credit': 1, 'trash': null }, { 'credit': 2, 'trash': null}];
    //const result = recipe.steps.filter(word => word.duration > 0);
    
    // const arr = [23,566,null,90,-32,undefined,32,-69,88,null];
    


    //  //3 dot dropdown
     const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <i class="fas fa-ellipsis-v"></i>
          {children}
      
        </a>
    ));

    // dynamic modal
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null);

    const expandModal = (steps) => {
        setSelectedProject(steps);
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setSelectedProject(null);
        setModalIsOpen(false);
    }


     // dynamic modal
    const [modalIsOpenTwo, setModalIsOpenTwo] = useState(false)
    //const [selectedProjectTwo, setSelectedProjectTwo] = useState(null);
 
    const expandModalTwo = () => {
        //setSelectedProjectTwo();
        setModalIsOpenTwo(true);
    }

    const closeModalTwo = () => {
       // setSelectedProjectTwo(null);
        setModalIsOpenTwo(false);
    }
    
    return (

        <Fragment>
        <MetaData title={recipe.name} />
        {loading ? <Loading/> : (
            <div className="new-recipe-full">
                <div className="new-recipe-page">
                    <div className="go-back-lab">
                        <div className="back-link">
                            <Link to="/recipes" className="back-link-text"><i class="fa fa-arrow-left"></i> Go Back To Recipes</Link>
                        </div>
                        <div className='header-recipe-logo'>
                            <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
                        </div>
                    </div>
                        <form className="recipe-full-box" encType='multipart/form-data'>
                            <div className="recipe-title">
                            
                                <div className="recipe-title-row">
                                    <h5>{recipe.name}</h5>
                                </div>
                                <div className="recipe-title-row">
                                    <div>Duration: {fancyTimeFormat(recipe.steps && recipe.steps.reduce((totalDuration, durations) => totalDuration + durations.duration, 0) )}</div>
                                    <div> Ratio: {recipe.ratio}</div>

                                    <div className="text-muted">
                                        Start Recipe 
                                    </div>
                                    {/* <Link to={`/recipe/run/${recipe._id}`}>
                                        Start Recipe 
                                    </Link> */}
                                    <div>
                                        <Link to={`/recipe/run/sheet/${recipe._id}`}>
                                            <p className="mb-0" style={{fontSize: '16px'}}>
                                                Recipe Sheet
                                            </p> 
                                        </Link>
                                    </div>
                                    
                                    {user && user._id == recipe.user &&
                                    <div className="row-flex  threeDots">
                                        <Dropdown >
                                            <Dropdown.Toggle as={CustomToggle}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                                <Dropdown.Header>Options</Dropdown.Header>
                                                <Dropdown.Item className="bg-dark">
                                                    <Link to={`/recipe/clone/${recipe._id}`}><p>Clone</p></Link> 
                                                </Dropdown.Item>
                                                <Dropdown.Item className="bg-dark text-light">
                                                    <Link className="bg-dark text-light" to={`/recipe/update/${recipe._id}`}>
                                                        <p>
                                                        Edit
                                                        </p>
                                                    </Link> 
                                                </Dropdown.Item>
                                                <Dropdown.Item className="bg-dark text-light">
                                                    <p onClick={() => deleteRecipeHandler(recipe._id)}>   
                                                        Delete
                                                    </p>
                                                </Dropdown.Item>
                                                <Dropdown.Item className="bg-dark text-light">
                                                    <p onClick={() => expandModalTwo()}>   
                                                        Info
                                                    </p>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    }
                                    {user && user._id !== recipe.user &&
                                    <div className="row-flex  threeDots">
                                        <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                        <Dropdown.Header>Options</Dropdown.Header>
                                        <Dropdown.Item className="bg-dark">
                                            <Link to={`/recipe/clone/${recipe._id}`}><p>Clone</p></Link> 
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <p onClick={() => expandModalTwo()}>   
                                                Info
                                            </p>
                                        </Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    }
                                    {user == undefined &&
                                    <div className="row-flex  threeDots">
                                        <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                        <Dropdown.Header>Options</Dropdown.Header>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <Link to='/login'>
                                                <p>
                                                   Login
                                                </p>
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-dark text-light">
                                            <p onClick={() => expandModalTwo()}>   
                                                Info
                                            </p>
                                        </Dropdown.Item>

                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="recipe-full-boxes">
                                <div className="recipe-half-box">
                                    <p><b>RECIPE DETAILS</b></p>
                                    <div className="recipe-half-box-left-top">
                                        <div className="recipe-half-box-left-top-left">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <label>COFFEE TYPE</label>
                                                <p>{recipe.coffeeType}</p>
                                            </div>
                                            {/* {recipe.user ? (
                                                    <img src={recipe.images[0].url} alt={recipe.name}/>
                                                ) :
                                                <div></div>
                                            } */}
                                            {recipe.method == "Chemex" &&
                                                <img src='/images/chemex_icon.png' alt={recipe.name}/>
                                            }
                                            {recipe.method == "AeroPress" &&
                                                <img src='/images/aeropress_icon.png' alt={recipe.name}/>
                                            }
                                            {recipe.method == "V60" &&
                                                <img src='/images/v60_icon-300x300.png' alt={recipe.name}/>
                                            }
                                            {recipe.method == "Turkish" &&
                                                <img src='/images/chemex_icon.png' alt={recipe.name}/>
                                            }
                                            {recipe.method == "Siphon" &&
                                                <span>Image Coming Soon</span>
                                            }
                                            {recipe.method == "Cold Brew" &&
                                                <span>Image Coming Soon</span>
                                            }
                                            {recipe.method == "French Press" &&
                                                <span>Image Coming Soon</span>
                                            }
                                            {recipe.method == "Coffee Maker" &&
                                                <span>Image Coming Soon</span>
                                            }

                                        </div>
                                        <div className="recipe-half-box-left-top-right">
                                            <label>DESCRIPTION</label>
                                            <p>{recipe.description}</p>
                                        </div>
                                    </div>
                                    <div className="recipe-half-box-left-bottom">
                                        <div className="recipe-half-box-left">    
                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="coffee_field">COFFEE</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>0</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>{isOn ? "1": "25"}</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>{isOn ? "2": "50"}</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="coffee_field"
                                                    className="form-control"
                                                    value={recipe.coffee}
                                                    min="0"
                                                    max={isOn ? "2": "50"}
                                                />
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <p className="">{isOn ? (recipe.coffee * 0.03527).toFixed(3) + ' oz': recipe.coffee +' g'}</p>
                                                    <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOn}>
                                                        Unit
                                                    </p>
                                                </div>
                                                
                                            </div>
                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="roast_field">ROAST</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Li</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Med</span>
                                                    <span className="p-0  mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Dark</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="roast_field"
                                                    className="form-control"
                                                    value={recipe.roast}
                                                    list="tickmarks"
                                                />
                                                <datalist id="tickmarks">
                                                    <option value="Light" label="Light"></option>
                                                    <option value="Medium Light"></option>
                                                    <option value="Medium"></option>
                                                    <option value="Medium Dark"></option>
                                                    <option value="Dark" label="Dark"></option>
                                                </datalist>
                                                <p>{recipe.roast}</p>
                                            </div>

                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="grind_field">GRIND</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <p className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>1</p>
                                                    <p className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>5</p>
                                                    <p className="p-0  mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>10</p>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <p className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</p>
                                                    <p className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</p>
                                                    <p className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</p>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="grind_field"
                                                    className="form-control"
                                                    value={recipe.grind}
                                                    list="tickmarksTwo"
                                                    step="10"
                                                    //step="1"
                                                />
                                                <datalist id="tickmarksTwo">
                                                    <option value="Extra Fine" label="1"></option>
                                                    <option value="Extra Fine"></option>
                                                    <option value="Fine"></option>
                                                    <option value="Fine"></option>
                                                    <option value="Medium" label="5"></option>
                                                    <option value="Medium"></option>
                                                    <option value="Coarse"></option>
                                                    <option value="Coarse"></option>
                                                    <option value="Extra Coarse"></option>
                                                    <option value="Extra Coarse" label="10"></option>
                                                </datalist>
                                                <p>{recipe.grind}</p>
                                            </div>
                                        
                                        </div>
                                        <div className="recipe-half-box-left">

                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="water_field">WATER</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>0</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>300</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>600</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="water_field"
                                                    className="form-control"
                                                    value={recipe.water}
                                                    min="0"
                                                    max="600"
                                                />
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <p className="">{isOnTwo ? recipe.water + ' ml' : recipe.water +' g'}</p>
                                                    <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOnTwo}>
                                                        Unit
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0">
                                                <label className="mb-0" htmlFor="temp_field">TEMPERATURE</label>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>0</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>150</span>
                                                    <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>300</span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                    <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                </div>
                                                <input
                                                    type="range"
                                                    id="temp_field"
                                                    className="form-control"
                                                    value={recipe.temperature}
                                                    min="0"
                                                    max="300"
                                                />
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <p className="">{isOnThree ? ((5/9) * (recipe.temperature - 32)).toFixed(3) + ' c': recipe.temperature +' f'}</p>
                                                    <p className="" style={{fontSize: '.8rem',cursor: 'pointer',borderBottom: '.3px solid white'}} onClick={toggleIsOnThree}>
                                                        Unit
                                                    </p>
                                                </div>
                                            </div>
                                            {recipe.method == "AeroPress" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">ORIENTATION</label>
                                                        <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Standard</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>Inverted</span>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                        <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        id="ratio_field"
                                                        className="form-control"
                                                        value={recipe.CustomMethodField}
                                                        step="1"
                                                        min="1"
                                                        max="2"
                                                    />
                                                    {/* <p>{recipe.CustomMethodField}</p> */}
                                                </div>
                                            }

                                             {recipe.method == "V60" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">CUP SIZE</label>
                                                        <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>1</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>2</span>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                        <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        id="ratio_field"
                                                        className="form-control"
                                                        value={recipe.CustomMethodField}
                                                        step="1"
                                                        min="1"
                                                        max="2"
                                                    />
                                                    {/* <p>{recipe.CustomMethodField}</p> */}
                                                </div>
                                            }

                                            {recipe.method == "Chemex" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">CUP SIZE</label>
                                                        <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>3 cups</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>6 cups</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>8 cups</span>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                        <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        id="ratio_field"
                                                        className="form-control"
                                                        value={recipe.CustomMethodField}
                                                        min="1"
                                                        max="3"
                                                    />
                                                    {/* <p>{recipe.CustomMethodField}</p> */}
                                                </div>
                                            }
                                            {recipe.method == "V60" &&
                                                    <div className="form-group mb-0">
                                                        <label className="mb-0" htmlFor="ratio_field">CUP SIZE</label>
                                                        <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0" >
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>1</span>
                                                        <span className="p-0 mb-0" style={{fontSize: '.8rem',padding: '0px',margin: '0px'}}>2</span>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center p-0 mb-0 mt-0">
                                                        <span className="p-0 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                        <span className="pt-0 pl-0 pb-0 pr-2 mb-1 mt-0" style={{paddingTop: '0px',fontSize: '.8rem',margin: '0px'}}>•</span>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        id="ratio_field"
                                                        className="form-control"
                                                        value={recipe.CustomMethodField}
                                                        step="1"
                                                        min="1"
                                                        max="2"
                                                    />
                                                    {/* <p>{recipe.CustomMethodField}</p> */}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='recipe-half-box-steps'>
                                <p><b>RECIPE STEPS</b></p>
                                    <div style={{overflow: 'auto', height: 'inherit', display: 'block', }}>        
                                        <DragDropContext>
                                            <Droppable droppableId="characters">
                                                {(provided) => (
                                                <div className="steps-box" {...provided.droppableProps} ref={provided.innerRef}>
                                                    {recipe.steps && recipe.steps.map(( steps) => {
                                                        return (
                                                            <>
                                                        <Draggable className="steps-box" key={steps.order} >
                                                            {(provided) => (
                                                            <div>

                                                                <div key={steps._id} className="step-box" onClick={() => expandModal(steps)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>                                                                {/* <div className="step-box" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}> */}
                                                                    
                                                                    <div className="step-box-section">
                                                                        <p>{ steps.name }</p>
                                                                        {steps.name == 'Pour' &&
                                                                            <img className="recipe-card-img" src={pourImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Swirl' &&
                                                                            <img className="recipe-card-img" src={swirlImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Rinse' &&
                                                                            <img className="recipe-card-img" src={rinseImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Enjoy' &&
                                                                            <img className="recipe-card-img" src={enjoyImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Invert' &&
                                                                            <img className="recipe-card-img" src={invertImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Custom Step' &&
                                                                            <img className="recipe-card-img" src={customImage[0].url} alt={steps.name}/>
                                                                        }
                                                                        {steps.name == 'Bloom' &&
                                                                            <img className="recipe-card-img" src={bloomImage[0].url} alt={steps.name} />
                                                                        }
                                                                        {steps.name == 'Wait Time' &&
                                                                           <img className="recipe-card-img" src={waitImage[0].url} alt={steps.name} />
                                                                        }
                                                                        {/* <img className="recipe-card-img" src={steps.image[0].url} alt={steps.name}/> */}
                                                                    </div>
                                                                    {/* <div className="step-box-section">
                                                                        <p placeholder="notes">{ steps.notes }</p>
                                                                    </div> */}
                                                                    <div className="step-box-section">
                                                                        <p>ORDER: { steps.order }</p>
                                                                        <div>
                                                                            {/* <p type="number" min='0' max='10'></p>01:<p type="number" min='0' max='59' placeholder="seconds">00</p> */}
                                                                            <p>{fancyTimeFormat(steps.duration)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </Draggable>

                                                        {/* dynamic modal */}
                                                        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modal-box" size="lg"aria-labelledby="contained-modal-title-vcenter"centered style={{
                                                            overlay: {
                                                            position: 'fixed',
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            backgroundColor: 'transparent',
                                                            color: 'white',
                                                            
                                                            },
                                                            content: {
                                                            position: 'absolute',
                                                            top: '20%',
                                                            left: '20%',
                                                            right: '20%',
                                                            bottom: '20%',
                                                            border: '1px solid #ccc',
                                                            color: 'white',
                                                            overflow: 'auto',
                                                            WebkitOverflowScrolling: 'touch',
                                                            outline: 'none',
                                                            padding: '20px',
                                                            backgroundColor: '#343A40',
                                                            }
                                                        }}>
                                                            <h2>{selectedProject && selectedProject.name}</h2>
                                                            <img className="modal-image" src={selectedProject && selectedProject.image[0].url} alt={selectedProject && selectedProject.name}/>
                                                            <p>NOTES: {selectedProject && selectedProject.notes}</p>
                                                            <p>ORDER: {selectedProject && selectedProject.order}</p>
                                                            <p>POUR: {selectedProject && selectedProject.pour}</p>
                                                            <p>DURATION: {fancyTimeFormat(selectedProject && selectedProject.duration)}</p>
                                                            <div>
                                                            <button onClick={closeModal}>Close Modal</button>
                                                            </div>
                                                        </Modal>
                                                    </>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        
                                    </div>
                                     {/* dynamic modal */}
                                     <Modal isOpen={modalIsOpenTwo} onRequestClose={closeModalTwo} className="modal-box" size="lg"aria-labelledby="contained-modal-title-vcenter"centered style={{
                                        overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'transparent',
                                        color: 'white',
                                        
                                        },
                                        content: {
                                        position: 'absolute',
                                        top: '20%',
                                        left: '20%',
                                        right: '20%',
                                        bottom: '20%',
                                        border: '1px solid #ccc',
                                        color: 'white',
                                        overflow: 'auto',
                                        WebkitOverflowScrolling: 'touch',
                                        outline: 'none',
                                        padding: '20px',
                                        backgroundColor: '#343A40',
                                        }
                                    }}>
                                        <h2>Info Section</h2>
                                        <div className="info-modal" style={{width: '100%'}}>
                                            <div className="info-modal-section" >
                                                <h4>Recipe Details</h4>
                                                <p>You can change the unit of measurement.</p>
                                            
                                            </div>
                                            <div className="info-modal-section">
                                                <h4>Recipe Steps</h4>
                                                <p>Recipe durations is added according to the steps duration.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <button onClick={closeModalTwo}>Close Modal</button>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </form>
                </div>
            </div>
        )}
        </Fragment>
    )
}

export default RecipeDetails;