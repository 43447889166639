import React from 'react';
import Header from './Header';
import Footer from './footer'

const LayoutTwo = ({ children }) => {
    return(
        <>
            <Header />
                <main>
                    {
                        children
                    }
                </main>
        </>
    )
}

export default LayoutTwo;