import React, {Component} from 'react'
import './TabsBrew.scss';
class Tabs extends Component {
    constructor(props) {
      super();
      this.state = {
        active: 0
      }
    }
    
    select = (i) => {
      let _this = this;
      return function() {
        _this.setState({
          active: i
        });
      }
    }
    
    renderTabs = () => {
      return React.Children.map(this.props.children, (item, i) => {
        if (i%2 === 0) {
          let active = this.state.active === i ? 'active' : '';
          return <button onClick={this.select(i)} className={`${active} tab`}>{item}</button>;
        }
      });
    }
    
    renderContent() {
      return React.Children.map(this.props.children, (item, i) => {
        if (i-1 === this.state.active) {
          return <div className='content'>{item}</div>;
        } else {
          return;
        }
      });
    }
    
    render() {
      return (
        <div className="tabs-brew">
          {this.renderTabs()}
          {this.renderContent()}
        </div>
      );
    }
  }
  
  class AeroPressTab extends Component {
    render() {
      return(
        <main>
          <Tabs>
            FRUITIER RECIPE
            <span>
              <ul className="tabs-brew-list">
                <li>AeroPress</li>
                <li>AeroPress filter</li>
                <li>16 grams (4 tbsp) Craft Coffee (setting 3.5 grind). The key for a fruitier taste is preferred 
                  coffees from Africa. Ethiopia, Kenya and Rwanda. 
                  Helps to push the acidity and some sweetness 👌.</li>
                <li>240 grams of water at 208F water, plus additional for rinsing</li>
                <li>Pouring Kettle (gooseneck preferred)</li>
                <li>Burr grinder (recommended)</li>
                <li>Scale (recommended)</li>
                <li>Stirring utensil</li>
                <li>Water to coffee ratio is 15:1</li>
              </ul>
            </span>
            BREWING ENGINEERS RECIPE
            <span>
              <ul className="tabs-brew-list">
                <li>AeroPress</li>
                <li>AeroPress filter</li>
                <li>18 grams (4 tbsp) Craft Coffee (setting 6 grind). This recipe works well with single origin coffees that are light to medium roast</li>
                <li>205 gram of water at 205F, plus additional for rinsing</li>
                <li>Pouring Kettle (gooseneck preferred)</li>
                <li>Burr grinder (recommended)</li>
                <li>Scale (recommended)</li>
                <li>Stirring utensil</li>
                <li>Water to coffee ratio is 11:1</li>
              </ul>
            </span>
            ESPRESSO RECIPE
            <span>
              <ul className="tabs-brew-list">
                <li>AeroPress</li>
                <li>AeroPress filter</li>
                <li>22 grams (5 tbsp) Craft Coffee (setting 4 grind)</li>
                <li>260 grams of water at 203F, plus additional for rinsing</li>
                <li>Pouring Kettle (gooseneck preferred)</li>
                <li>Burr grinder (recommended)</li>
                <li>Scale (recommended)</li>
                <li>Stirring utensil. Place the plunger on top, 
                  and plunge after 3 more minutes of brewing</li>
                <li>Water to coffee ratio is 12:1</li>
              </ul>
            </span>
          </Tabs>
        </main>
      );
    }
  }
 
export default AeroPressTab;