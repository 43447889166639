import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import MetaData from '../layout/MetaData'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword, clearErrors } from '../../actions/userActions'

const ForgotPassword = () => {

    const [email, setEmail] = useState('')

    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, loading, message } = useSelector(state => state.forgotPassword)

    useEffect(() => {

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (message) {
            alert.success(message)
        }

    }, [dispatch, alert, error, message])

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set('email', email);

        dispatch(forgotPassword(formData))
    }

    return (
        <Fragment>
            <MetaData title={'FORGOT PASSWORD'} />

            <div className="login-page">
                <div className="go-back">
                    <Link to="/" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Homepage</Link>
                </div>
                <div className="login">
                    <form className="login-form" onSubmit={submitHandler}>
                        <h1 className="mb-3">FORGOT PASSWORD</h1>
                        <div className="group">
                            {/* <label htmlFor="email_field">Enter Email</label> */}
                            <input
                                type="email"
                                id="email_field"
                                className="login-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder='EMAIL'
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <img className="label-login" src='/images/email.png' alt=""/>
                        </div>

                        <p>
                            <Link to="/login" >Back To Login?</Link>
                        </p>

                        <div className="div_button"> 
                            <button
                                id="forgot_password_button"
                                type="submit"
                                className="btn btn-block py-3"
                                disabled={loading ? true : false} >
                                SEND EMAIL
                            </button>
                        </div>

                    </form>
                </div>
            </div>

        </Fragment>
    )
}

export default ForgotPassword