import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Login.styles.scss'
import Loading from '../pages/loading/loading'
//import Loader from '../layout/Loader'
import MetaData from '../layout/MetaData'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { login, clearErrors } from '../../actions/userActions'

const Login = ({ history, location }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const alert = useAlert();
    const dispatch = useDispatch();

    const { isAuthenticated, error, loading } = useSelector(state => state.auth);

    const redirect = location.search ? location.search.split('=')[1] : '/'

    useEffect(() => {

        if (isAuthenticated) {
            history.push(redirect)
        }

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

    }, [dispatch, alert, isAuthenticated, error, history])

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password))
    }

    return (
        <Fragment>
            {loading ? <Loading/> : ( 
                <Fragment>
                    <MetaData title={'LOGIN'} />

                    <div className="login-page">
                        <div className="go-back mt-2">
                            <Link to="/" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Homepage</Link>
                        </div>
                        <div className="login">
                       
                            <form className="login-form" onSubmit={submitHandler}>
                                <h1>LOGIN</h1>
                                <div className="group">
                                    {/* <label htmlFor="email_field">Email</label> */}
                                    <input
                                        type="email"
                                        id="email_field"
                                        className="login-input"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder="EMAIL"
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <img className="label-login" src='/images/email.png' alt=""/>
                                </div>

                                <div className="group">
                                    {/* <label htmlFor="password_field">Password</label> */}
                                    <input
                                        type="password"
                                        id="password_field"
                                        className="login-input"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        placeholder="PASSWORD"
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <img className="label-login" src='/images/locked-outline.png' alt=""/>
                                </div>

                                <Link to="/password/forgot" className="float-right mb-4">Forgot Password?</Link>

                                <div className="div_button">
                                    <button
                                        id="login_button"
                                        type="submit"
                                        className="btn btn-block py-3"
                                    >
                                        LOGIN
                                    </button>
                                </div>
    
                                <p>
                                    Don't have an account?<Link to="/register" > Sign Up</Link>
                                </p>
                            </form>
                        </div>
                    </div>


                </Fragment>
            )}
        </Fragment>
    )
}

export default Login