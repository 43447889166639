import React, {Component} from 'react'
import './TabsBrew.scss';
class Tabs extends Component {
    constructor(props) {
      super();
      this.state = {
        active: 0
      }
    }
    
    select = (i) => {
      let _this = this;
      return function() {
        _this.setState({
          active: i
        });
      }
    }
    
    renderTabs = () => {
      return React.Children.map(this.props.children, (item, i) => {
        if (i%2 === 0) {
          let active = this.state.active === i ? 'active' : '';
          return <button onClick={this.select(i)} className={`${active} tab`}>{item}</button>;
        }
      });
    }
    
    renderContent() {
      return React.Children.map(this.props.children, (item, i) => {
        if (i-1 === this.state.active) {
          return <div className='content'>{item}</div>;
        } else {
          return;
        }
      });
    }
    
    render() {
      return (
        <div className="tabs-brew">
          {this.renderTabs()}
          {this.renderContent()}
        </div>
      );
    }
  }
  
  class V60Tab extends Component {
    render() {
      return(
        <main>
          <Tabs>
            1 CUP
            <span>
                <ul className="tabs-brew-list">
                    <li>Hario V60 dripper</li>
                    <li>V60 paper filter</li>
                    <li>17 grams Craft Coffee beans</li>
                    <li>250 grams of water at 96F, plus additional for rinsing</li>
                    <li>Pouring Kettle (gooseneck preferred)</li>
                    <li>Burr grinder (recommended)</li>
                    <li>Scale (recommended)</li>
                    <li>Stirring utensil</li>
                </ul>
            </span>
            2 CUPS
            <span>
              <ul className="tabs-brew-list">
                  <li>Hario V60 dripper</li>
                  <li>V60 paper filter</li>
                  <li>32 grams Craft Coffee beans</li>
                  <li>500 grams of water at 97F, plus additional for rinsing</li>
                  <li>Pouring Kettle (gooseneck preferred)</li>
                  <li>Burr grinder (recommended)</li>
                  <li>Scale (recommended)</li>
                  <li>Stirring utensil</li>
              </ul>
            </span>
          </Tabs>
        </main>
      );
    }
  }
 
export default V60Tab;