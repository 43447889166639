import React, {Component} from 'react';
//import logo from '../../Images/logo.png'
import './loading.styles.scss'
import MetaData from '../../layout/MetaData'


class Loading extends Component{
    render(){
        return(
            <div className="loadingPage">
                <MetaData title={'LOADING'} />
                {/* <img className="cup_logo" src={logo} alt=""/> */}
                <h1>BREWING ENGINEERS</h1>
                <p>COFFEE COMPANY</p>
                <div className='cup'>
                    <div className='cup__top'></div>
                    <div className='cup__body'>
                    </div>
                </div>
                <div className='shadow'></div>
                <i>LOADING...</i>
                <hr className='hr'/>
            </div>
        )
    }
}

export default Loading;