import React from 'react';
import './footer.styles.scss';
//import cat from '../../images/ezgif.com-gif-maker.gif'
import {Link} from 'react-router-dom'

const Footer = () => {
    //const year = new Date().getFullYear();
    return(
        <div className='footer'>
            {/* {year} BLACKBIRD COFFEE COMPANY */}
            <img className="cat" src='/images/ezgif.com-gif-maker.gif' alt="" /> 
            <div className="footer-container">
            <div className="footer-left">
                <div className="footer-title">
                    <p>BREWING</p> <p>ENGINEERS</p>

                </div>
                <div className="footer-links">
                    <a className="text-light" href='https://www.instagram.com/brewingengineerscoffee/'><i class="fab fa-instagram"></i></a>
                    {/* <i class="fab fa-dribbble"></i> */}

                </div>
            </div>
            <div className="footer-middle">
                <div className="footer-big-text">
                    <h1>THOU SHALT NOT DRINK BAD COFFEE</h1>
                </div>
                <div className="footer-small-text">
                    <h4>EMAIL US AT</h4>
                    <h4>info@brewingengineers.io</h4>
                    <a target="_blank" href='https://ko-fi.com/brewingengineers'>Buy us a cup of coffee. ☕</a><br/>
                    <Link to='/private-policy' className='footer-link'>private policy</Link> | <Link to='/shipping-info' className='footer-link'>shipping info</Link>
                </div>
            </div>
            <div className="footer-right">
                <div className="footer-tabs">
                    <p>PAGES</p>
                    <hr/>
                    <ul>
                        <li><Link to='/' className='footer-link'>HOME</Link></li>
                        <li><Link to='/about' className='footer-link'>ABOUT</Link></li>
                        <li><Link to='/brew' className='footer-link'>BREW</Link></li>
                        <li><Link to='/shop' className='footer-link'>SHOP</Link></li>
                    </ul>
                </div>
                <div className="footer-labs">
                    <p>LAB</p>
                    <hr/>
                    <ul>
                        <li ><Link to='/chemex' className='footer-link'>CHEMEX</Link></li>
                        <li><Link to='/aeropress' className='footer-link'>AEROPRESS</Link></li>
                        <li><Link to='/v60' className='footer-link'>V60</Link></li>
                        <li><Link to='/espresso' className='footer-link'>ESPRESSO</Link></li>
                    </ul>
                </div>

            </div>
        </div>
        </div>
    );
}

export default Footer;