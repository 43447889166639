import React from 'react';
import { Link } from 'react-router-dom'
import MetaData from '../../layout/MetaData'

const PositionOne = () => {
    return(
        <div className="positions-page">
            <MetaData title={'JOBS'} />
            
            <div className="go-back">
                <Link to="/about" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Positions</Link>
            </div>
            
            <div className='cart-page'>
            <p>Atlanta/ Remote</p>
            <h1>Full Stack Engineer</h1>
            <p>We're hiring a Software Engineer with fullstack 
                experience to add to our engineering team here 
                at <i>BREWING ENGINEERS</i> and shape the future of coffee in tech. Here are some of the technologies we work with -
                React, Javascript, Node, Express, SASS, MongoDB, Stripe, AWS, Github, Git, SendGrid, Heroku, External APIs...
            </p>
            <h1>Our Core Beliefs</h1>
            <h3>Workplace mastery</h3>
            <p><i>BREWING ENGINEERS</i> is growing and always on the lookout for top-tier talent. 
                Our team moves fast, works hard, and has passion for what they do. If you’re
                 looking for a normal 9 to 5, <i>BREWING ENGINEERS</i> is not for you.
                  We like passionate dynamic self-starters with a sense of humor 
                  and a desire to make the world a better place with technology. 
                  Does this sound like you? Cool!
            </p>
            <h3>Geeks with a passion</h3>
            <p>Are you nuts over Node.js? Do you rave about Javascript? Are you a tech geek with a great eye for design?
                You may need psychiatric help. That or you may be interested 
                in a software development position with us. We are hiring engineers 
                of all experience levels!
            </p>
            <h1>To Apply</h1>
                <p>Just send us your resume and Github profile at info@brewingengineers.io

                If you want to toss in something extra, tell us about something you built that your particularly proud of.</p>
                </div>
            </div>
    )
}

export default PositionOne;