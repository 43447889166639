import React, { Fragment } from 'react'
import Layout from '../../layout/layout'
import { Link } from 'react-router-dom';

import MetaData from '../../layout/MetaData'
import '../../cart/Checkout.scss'

const YellowHoney = () => {

    return (
        <Layout>
        <Fragment>

            <MetaData title={'Yellow Honey Process'} />

            <div className="go-back">
                <Link to="/product/brewingengineerscostaricacoffee" className="back-link-checkout"><i class="fa fa-arrow-left"></i> Go Back To Product</Link>
            </div>

            <div className='full-page'> 
            <div className='about-form'>
            <h2 className="mb-3 pl-5 pr-5">COSTA RICA</h2>
                <p className="pl-5 pr-5">Costa Rica is one of the top producers of coffee, and, as their 
                number three export, is incredibly important to the country’s 
                economy. Arabica coffee was first introduced to the country directly from Ethiopia 
                in the late 1770’s. Like many countries’ governments, the Costa Rican 
                government encouraged farming by offering plots of land to farmers who wanted 
                to grow and harvest the plants. By 1829, coffee production was a 
                larger source of revenue than tobacco, cacao, and sugar cane. Today, 
                many Nicaraguan immigrants are often employed as seasonal workers 
                on plantations to harvest coffee during peak seasons.
                </p>

                <p className="pl-5 pr-5">While Costa Rican beans are considered to be some of the highest 
                 quality in the world, beans from the Tarrazú region are the best of the best. Oscar and 
                 Francisca Chacon are the owners and operators of the Cumbres del 
                 Poas farm, and are the first to have a certified organic micro mill. 
                 Their incredible passion for producing the best coffee and dedication 
                 to perfecting every step of the process shows in the superior 
                 quality of their honey processed beans.
                </p>
                <h2 className="mb-3 pl-5 pr-5">HONEY PROCESS</h2>
                <p className="pl-5 pr-5">Honey processed coffee is unique in that while the skin 
                and pulp are removed from the cherry, the mucilage, a sugary, 
                sticky outer layer, is retained during the drying stage. This 
                mucilage is sometimes called “honey”, which is why this process 
                is called Honey Process. Honey processed coffees are significantly 
                less acidic than washed or natural/sun dried coffees and have much 
                more character and sweetness than traditional fully washed coffees.
                </p>
                <p className="pl-5 pr-5">
                Farmers have begun to assign colors to each honey process. Colors 
                are assigned to indicate the amount of sunlight each cherry is 
                exposed to, yellow being the most and black being the least. 
                Yellow honey coffees are usually dried in the sun for around a 
                week, red honey is dried for two to three weeks, usually in the 
                shade, and black honey is exposed to as little light as possible 
                and must dry for at least two weeks. The longer the dry time, the 
                more fruit-forward the end result tastes.
                </p>
            </div>
            </div>

        </Fragment>
    </Layout>
    )
}

export default YellowHoney