import imgSrc from "../../../images/artwork.jpg";
import imgSrc2 from "../../../images/artwork2.jpg";
import imgSrc3 from "../../../images/artwork3.jpg";
import imgSrc4 from "../../../images/artwork4.jpg";
import imgSrc5 from "../../../images/artwork5.jpg";
import cali from "../../../images/cali-wataboi.mp3";
import fifty from "../../../images/50-tobylane.mp3";
import iwonder from "../../../images/Craves - Got 2 Know.mp3";
import gal from "../../../images/Galimatias - Ocean Floor Kisses.mp3";
import med from "../../../images/Medasin_-_Daydream_feat_Joba[Mp3Converter.net].mp3";

// All of these artists are at https://pixabay.com/music/search/mood/laid%20back/
export default [
  {
    title: "Cali",
    artist: "Wataboi",
    audioSrc: cali,
    image: imgSrc,
    color: "#00aeb0"
  },
  {
    title: "50",
    artist: "tobylane",
    audioSrc: fifty,
    image: imgSrc2,
    color: "#ffb77a"
  },
  {
    title: "Got To Know",
    artist: "Craves",
    audioSrc: iwonder,
    image: imgSrc3,
    color: "#5f9fff"
  },
  {
    title: "Galimatias",
    artist: "Ocean Floor Kisses",
    audioSrc: gal,
    image: imgSrc5,
    color: "#ffb77a"
  },
  {
    title: "Daydream",
    artist: "Medasin",
    audioSrc: med,
    image: imgSrc4,
    color: "#5f9fff"
  }
];
