
import React, { Fragment, useState, useEffect } from 'react'
import MetaData from '../layout/MetaData'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { updatePassword, clearErrors } from '../../actions/userActions'
import { UPDATE_PASSWORD_RESET } from '../../constants/userConstants'

const UpdatePasswordTab = ({ history }) => {

    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')

    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, isUpdated, loading } = useSelector(state => state.user)

    useEffect(() => {

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (isUpdated) {
            alert.success('Password updated successfully')

            history.push('/profiletabs')

            dispatch({
                type: UPDATE_PASSWORD_RESET
            })
        }

    }, [dispatch, alert, error, history, isUpdated])

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set('oldPassword', oldPassword);
        formData.set('password', password);

        dispatch(updatePassword(formData))
    }

    return (
        <Fragment>
            <MetaData title={'UPDATE PASSWORD'} />

            <div className="login-page">
                <div className="checkout">
                    <form className="checkout-form"onSubmit={submitHandler}>
                        <h1 className="mt-2 mb-5">UPDATE PASSWORD</h1>

                        <div className="form1">
                            <input
                                type="password"
                                id="old_password_field"
                                className="form-control"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <label for="old_password_field" className="label-name">
                                <span  className="content-name">OLD PASSWORD</span>
                            </label>
                        </div>

                        <div className="form1">
                            <input
                                type="password"
                                id="new_password_field"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <label for="new_password_field" className="label-name">
                                <span  className="content-name">NEW PASSWORD</span>
                            </label>
                        </div>
                        <div className="div_button">
                            <button type="submit"  className="btn btn-block py-3" disabled={loading ? true : false} >UPDATE PASSWORD</button>
                        </div>
                    </form>
                </div>
            </div>

        </Fragment>
    )
}

export default UpdatePasswordTab