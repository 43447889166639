import React, {Component} from 'react';
import AudioPlayer from "./AudioPlayer";
import { Link } from 'react-router-dom'
import tracks from "./tracks";
import MetaData from '../../layout/MetaData'

class Playlist extends Component{
    render(){
        return(
            <div className='playlist-page'>
                <MetaData title={"PLAYLIST"} />
                <div className="go-back">
          <Link to="/" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Homepage</Link>
      </div>
                <AudioPlayer tracks={tracks} />
            </div>
        )
    }
}

export default Playlist;

