import React, { useState, useEffect } from 'react'
import TimerEspresso from './TimerEspresso'
import { Link } from 'react-router-dom';
import ProgressBar from '../ProgressBar';


function TimerEspressoTwo() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 7000)
  }, [])

  return (
    <>
    {loading === false ? (
    <>
        <TimerEspresso/>
    </>
      ) : (
        <div className="loading-timer-page">
          <div className='header-lab'>
                <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
          </div>
          <div className="load-timer">
          <p>BEFORE YOU BREW</p>
          <h1>MAKE SURE YOU RINSE YOUR FILTER</h1>
          <img src='/images/2_espresso.png' alt="espresso"/>
          <div className='skip-link'>
            <Link to='/timer/espresso/brew'>Skip</Link>
          </div>
          <ProgressBar/>
        </div>
        </div>
      )}
      </>
  );
}

export default TimerEspressoTwo;