import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../pages/loading/loading'
import MetaData from '../layout/MetaData'

const Profile = () => {

    const { user, loading } = useSelector(state => state.auth)

    return (
        <Fragment>
            {loading ? <Loading/> : (
                <Fragment>
                    <MetaData title={'YOUR PROFILE'} />

                    <h4  className="mt-5 ml-5">MY PROFILE</h4>
                    <div className="row justify-content-around mt-5 user-info m-0 profile-page">
                        <div className="col-8 col-md-3 p-0">
                            <figure className='avatar avatar-profile'>
                                <img className="rounded-circle img-fluid" src={user.avatar.url} alt={user.name} />
                            </figure>
                            <Link to="/me/update" id="edit_profile" id="login_btn" className="btn-block my-2">
                                EDIT PROFILE
                            </Link>
                        </div>

                        <div className="col-8 col-md-5 p-0">
                            <h4>FULL NAME</h4>
                            <p>{user.name}</p>

                            <h4>EMAIL ADDRESS</h4>
                            <p>{user.email}</p>

                            <h4>JOINED ON</h4>
                            <p>{String(user.createdAt).substring(0, 10)}</p>

                            {user.role !== 'admin' && (
                                <Link to="/orders/me" id="login_btn" className="btn-block mt-5">
                                    MY ORDERS
                                </Link>
                            )}

                            <Link to="/password/update" id="login_btn" className="btn-block mt-3 mb-4">
                                CHANGE PASSWORD
                            </Link>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Profile