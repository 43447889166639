import { useEffect, useState } from'react';
import './App.css';
//import {Switch, Route} from 'react-router-dom';
//import Layout from './components/layout/layout';
import { BrowserRouter as Router, Route , Switch,Redirect} from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

//payment
import Cart from './components/cart/Cart'
import Shipping from './components/cart/Shipping'
import Payment from './components/cart/Payment'
import ConfirmOrder from './components/cart/ConfirmOrder'
import OrderSuccess from './components/cart/OrderSuccess'

//payment guess
import ShippingGuess from './components/cart/guessCheckout/ShippingGuess'
import PaymentGuess from './components/cart/guessCheckout/PaymentGuess'
import ConfirmOrderGuess from './components/cart/guessCheckout/ConfirmOrderGuess'
import OrderSuccessGuess from './components/cart/guessCheckout/OrderSuccessGuess'

// //demo recipes
// import RecipeListBeta from './components/recipe-beta/recipeList'
// import RecipeChemexDetails from './components/recipe-beta/RecipeDetailsChemex'

import { AnimatePresence } from "framer-motion";

//orders
import ListOrders from './components/order/ListOrders';

//pages
import Home from './components/pages/home/Home'
import Shop from './components/Shop';
import Playlist from './components/pages/Playlist/Playlist'
import AboutPage from './components/pages/about-page/about-page'
import NotFound from './not-found';
import BrewPage from './components/pages/brew/brew-page';
import ChemexPage from './components/pages/brew/chemex';
import AeroPressPage from './components/pages/brew/aeropress';
import V60Page from './components/pages/brew/v60';
import EspressoPage from './components/pages/brew/espresso';
import PositionOne from './components/pages/positions/position-one';
import TimerEspresso from './components/pages/Timer/Espresso/TimerEspresso';
import TimerChemex from './components/pages/Timer/chemexTimer/TimerChemex';
import TimerAeropress from './components/pages/Timer/aeropressTimer/TimerAeropress';
import TimerV60 from './components/pages/Timer/v60Timer/TimerV60';
import TimerChemexOne from './components/pages/Timer/chemexTimer/TimerChemexOne';
import TimerAeropressOne from './components/pages/Timer/aeropressTimer/TimerAeropressOne';
import TimerV60One from './components/pages/Timer/v60Timer/TimerV60One';
import TimerEspressoOne from './components/pages/Timer/Espresso/TimerEspressoOne';

//auth
import Login from './components/user/Login';
import Register from './components/user/Register';
import Profile from './components/user/Profile';
import UpdateProfile from './components/user/UpdateProfile';
import UpdatePassword from './components/user/UpdatePassword';
import ForgotPassword from './components/user/ForgotPassword';
import NewPassword from './components/user/NewPassword'
import ProtectedRoute from './components/route/ProtectedRoute';
import { loadUser } from './actions/userActions';
import ProfileTabs from './components/admin/profile/ProfileTabs';

//yelp coffee finder
import CoffeeFinder from './components/pages/CoffeeFinder/CoffeeFinder';
import BusinessDetail from './components/pages/CoffeeFinder/BusinessDetail';


//products
import store from './store';
import axios from 'axios';
import ProductDetails from './components/product/productDetails';
import YellowHoney from './components/pages/articles/YellowHoney';
import BrazilCerrado from './components/pages/articles/BrazilCerrado'

//recipes
import NewRecipe from './components/recipe/CreateRecipe';
import RecipeList from './components/RecipeList';
import RecipeDetails from './components/recipe/recipeDetails';
import UpdateRecipe from './components/recipe/UpdateRecipe';
import NewRecipeTest from './components/recipe/newRecipeTest'
import RunRecipe from './components/recipe/StartRecipe';
import RecipeSheet from './components/recipe/RecipeSheets';
import CloneRecipe from './components/recipe/CloneRecipe';

//admin
import Dashboard from './components/admin/Dashboard'
import ProductsList from './components/admin/ProductsList'
import NewProduct from './components/admin/newProduct'
import UpdateProduct from './components/admin/UpdateProduct'
import OrdersList from './components/admin/OrdersList';
import ProcessOrder from './components/admin/ProcessOrder';
import UsersList from './components/admin/UsersList';
import UpdateUser from './components/admin/UpdateUser';
import PrivatePolicy from './PrivatePolicy'
import ShippingInfo from './ShippingInfo'

//payment
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'
import OrderDetails from './components/order/OrderDetails';
//import { listenerCount } from 'cluster';

function App() {
  
  const [stripeApiKey, setStripeApiKey] = useState('');
  
  useEffect(() => {
    store.dispatch(loadUser())
    
    async function getStripApiKey() {
      const { data } = await axios.get('/api/v1/stripeapi');
      
      setStripeApiKey(data.stripeApiKey)
    }
    
    getStripApiKey();
    
  }, [])


  return (
    <Router>
      <Switch>
    <div className="App">
    <ScrollToTop>
        {/* <Layout> */}
        {/* <Route exact path="/*">
            <Route to="/error" component={NotFound}></Route>
          </Route> */}
        <AnimatePresence initial={true} exitBeforeEnter>
          <Route exact path="/" component={Home} />
        </AnimatePresence>
          <Route exact path="/search/:keyword" component={Home} />
          <Route exact path="/product/:id" component={ProductDetails} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/playlist" component={Playlist} />
          <Route exact path="/yellow-honey-process" component={YellowHoney} />
          <Route exact path="/cerrado-coffee" component={BrazilCerrado} />

          
          {/* <Route exact path="/comingsoon" component={ComingSoon} /> */}

          {/* <ProtectedRoute exact path='/recipe/create' component={TestRecipe}/> */}
          {/* <ProtectedRoute exact path='/recipe/test' component={NewRecipeTest}/> */}
          <ProtectedRoute exact path='/new' component={NewRecipe}/>
          <Route exact path="/recipes" component={RecipeList} />
          <Route exact path='/recipe/:id' component={RecipeDetails}/>
          <Route exact path='/recipe/run/sheet/:id' component={RecipeSheet}/>
          <Route exact path='/recipe/run/:id' component={RunRecipe}/>
          <ProtectedRoute exact path='/recipe/update/:id' component={UpdateRecipe}/>
          <ProtectedRoute exact path='/recipe/clone/:id' component={CloneRecipe}/>


          <Route exact path="/cart" component={Cart} />
          <ProtectedRoute exact path='/shipping' component={Shipping}/>
          <ProtectedRoute path="/confirm" component={ConfirmOrder} exact />
          <ProtectedRoute exact path='/success' component={OrderSuccess}/>
          {stripeApiKey &&
            <Elements stripe={loadStripe(stripeApiKey)}>
              <ProtectedRoute exact path="/payment" component={Payment} />
            </Elements>
          }

          <Route exact path='/shipping-guess' component={ShippingGuess}/>
          <Route path="/confirm-guess" component={ConfirmOrderGuess} exact />
          <Route exact path='/success-guess' component={OrderSuccessGuess}/>
          {stripeApiKey &&
            <Elements stripe={loadStripe(stripeApiKey)}>
              <Route exact path="/payment-guess" component={PaymentGuess} />
            </Elements>
          }

          <Route path="/coffee-finder" exact component={CoffeeFinder} />
          <Route path="/business/:id" exact component={BusinessDetail} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/password/forgot" component={ForgotPassword} />
          <Route exact path="/password/reset/:token" component={NewPassword} />

          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/brew" component={BrewPage} />
          <Route exact path="/chemex" component={ChemexPage} />
          <Route exact path="/v60" component={V60Page} />
          <Route exact path="/aeropress" component={AeroPressPage} />
          <Route exact path="/espresso" component={EspressoPage} />
          <Route exact path="/timer/chemex" component={TimerChemexOne} />
          <Route exact path="/timer/aeropress" component={TimerAeropressOne} />
          <Route exact path="/timer/v60" component={TimerV60One} />
          <Route exact path="/timer/espresso" component={TimerEspressoOne} />
          <Route exact path="/timer/chemex/brew" component={TimerChemex} />
          <Route exact path="/timer/aeropress/brew" component={TimerAeropress} />
          <Route exact path="/timer/v60/brew" component={TimerV60} />
          <Route exact path="/timer/espresso/brew" component={TimerEspresso} />

          <Route exact path="/position-one" component={PositionOne} />

          {/* <Route exact path="/recipes-beta" component={RecipeListBeta} />
          <Route exact path="/chemex-recipe-beta" component={RecipeChemexDetails} /> */}

          <Route exact path='/shipping-info' component={ShippingInfo}/>
          <Route exact path='/private-policy' component={PrivatePolicy}/>
          <ProtectedRoute exact path='/profiletabs' component={ProfileTabs}/>
          <ProtectedRoute exact path='/me' component={Profile}/>
          <ProtectedRoute exact path='/me/update' component={UpdateProfile}/>
          <ProtectedRoute exact path='/password/update' component={UpdatePassword}/>
          <ProtectedRoute exact path='/orders/me' component={ListOrders}/>
          <ProtectedRoute exact path='/order/:id' component={OrderDetails}/>

          <ProtectedRoute exact path='/dashboard' isAdmin={true} component={Dashboard}/>
          <ProtectedRoute exact path='/admin/products' isAdmin={true} component={ProductsList}/>
          <ProtectedRoute exact path='/admin/product' isAdmin={true} component={NewProduct}/>
          <ProtectedRoute exact path='/admin/product/:id' isAdmin={true} component={UpdateProduct}/>
          <ProtectedRoute exact path='/admin/orders' isAdmin={true} component={OrdersList}/>
          <ProtectedRoute exact path='/admin/order/:id' isAdmin={true} component={ProcessOrder}/>
          <ProtectedRoute exact path='/admin/users' isAdmin={true} component={UsersList}/>
          <ProtectedRoute exact path='/admin/user/:id' isAdmin={true} component={UpdateUser}/>
        {/* </Layout> */}
        {/* <Route path="*" component={NotFound} /> */}
        </ScrollToTop>
    </div>
      </Switch>
    </Router>
  );
}

export default App;