import React, { useState, useEffect } from 'react'
//import logo from './logo.svg';
//import './App.css';
//import LoadingScreen from './loading'
import TimerChemex from './TimerChemex'
import { Link } from 'react-router-dom';
import ProgressBar from '../ProgressBar';


function TimerChemexTwo() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 7000)
  }, [])

  return (
    <>
    {loading === false ? (
    <>
        <TimerChemex/>
    </>
      ) : (
        <div className="loading-timer-page">
          <div className='header-lab'>
            <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
          </div>
        <div className="load-timer">
          <p>BEFORE YOU BREW</p>
          <h1>MAKE SURE YOU RINSE YOUR FILTER</h1>
          <img src='/images/chemex_icon.png' alt="chemex"/>
          <div className='skip-link'>
            <Link to='/timer/chemex/brew'>Skip</Link>
          </div>
          <ProgressBar/>
        </div>
        </div>
      )}
      </>
  );
}

export default TimerChemexTwo;