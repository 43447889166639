import React, {Component} from 'react'
//import '../Methods/Tabs/Tabs.scss'
import ListOrdersTab from '../../order/ListOrdersTab';
import Profile from '../../user/Profile';
import UpdatePasswordTab from '../../user/UpdatePasswordTab'
import Layout from '../../layout/layout';
import Subscriptions from '../../user/Subscriptions';
import './ProfileTabs.styles.scss';

class Tabs extends Component {
    constructor(props) {
      super();
      this.state = {
        active: 0
      }
    }
    
    select = (i) => {
      let _this = this;
      return function() {
        _this.setState({
          active: i
        });
      }
    }
    
    renderTabs = () => {
      return React.Children.map(this.props.children, (item, i) => {
        if (i%2 === 0) {
          let active = this.state.active === i ? 'active' : '';
          return <button onClick={this.select(i)} className={`${active} tab`}>{item}</button>;
        }
      });
    }
    
    renderContent() {
      return React.Children.map(this.props.children, (item, i) => {
        if (i-1 === this.state.active) {
          return <div className='content'>{item}</div>;
        } else {
          return;
        }
      });
    }
    
    render() {
      return (
        <div className="tabs">
          {this.renderTabs()}
          {this.renderContent()}
        </div>
      );
    }
  }

  
  class ProfileTabs extends Component {
    render() {
      return(
        <Layout>
        <main className="account-page">
          <Tabs>
            PROFILE
            <span>
                <Profile />
            </span>
            ORDERS
            <span>
                <ListOrdersTab/>
            </span>
            SECURITY
            <span>
                <UpdatePasswordTab/>
            </span>
            SUBSCRIPTIONS
            <span>
                <Subscriptions/>
            </span>
          </Tabs>
        </main>
        </Layout>
      );
    }
  }
 
export default ProfileTabs;