import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../layout/layout';

import { useAlert } from 'react-alert'
import MetaData from '../../layout/MetaData'
import CheckoutSteps from '../CheckoutSteps'

import { useDispatch,useSelector } from 'react-redux'
// import { createOrderGuest, clearErrors } from '../../../actions/orderActions'

import { PayPalScriptProvider, PayPalButtons, FUNDING  } from "@paypal/react-paypal-js";

const ConfirmOrderGuess = ({ history }) => {

    const [isDisabled, setIsDisabled] = useState(true);

    const alert = useAlert();
    const { cartItems, shippingInfo } = useSelector(state => state.cart)
    //const { user } = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const { error } = useSelector(state => state.newOrderGuess)
    const [errors, setError] = React.useState(null);
    const [paid, setPaid] = React.useState(false);

    // useEffect(() => {

    //     if (error) {
    //         alert.error(error)
    //         dispatch(clearErrors())
    //     }

    // }, [dispatch, alert, error])

    
    // const orderGuest = {
    //     orderItems: cartItems,
    //     shippingInfo,
    //     //check later for creating an order
    //     // orderInfo,
    //     // totalPrice
    // }

    // const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));
    // if (orderInfo) {
    //     orderGuest.itemsPrice = orderInfo.itemsPrice
    //     orderGuest.shippingPrice = orderInfo.shippingPrice
    //     orderGuest.taxPrice = orderInfo.taxPrice
    //     orderGuest.totalPrice = orderInfo.totalPrice
    // }

    // const [succeeded, setSucceeded] = useState(false);
    // const [paypalErrorMessage, setPaypalErrorMessage] = useState("");

    
    // Calculate Order Prices
    const itemsPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
    const shippingPrice =  itemsPrice > 200 ? 0 : 4.50
    const taxPrice = Number((0.05 * itemsPrice).toFixed(2))
    //const totalPrice = (itemsPrice + shippingPrice + taxPrice).toFixed(2)
    const [totalPrice, setTotalPrice] = useState((itemsPrice + shippingPrice + taxPrice).toFixed(2))

    const fullNameGuessSaved = shippingInfo.fullNameGuess
    const emailGuessSaved = shippingInfo.emailGuess
    const [usedCoupon, setUsedCoupon] = useState(false)

    const validate =() => {
        const myRe = "BREWINGENGINEERS5";
        const coupon = myRe.trim();
        const input = document.getElementById('in').value;
        if(input.toUpperCase() == coupon.toUpperCase()) {
            document.getElementById('message').innerHTML="Promo Code is correct.";
            document.getElementById('err').innerHTML="";
            document.getElementById('discount-amount').innerHTML= '<p>Discount Amount:</p>';
            document.getElementById('discount-amount-value').innerHTML= '<p>- $5</p>';
            let totalPriceNormal = (itemsPrice + shippingPrice + taxPrice - 5).toFixed(2)
            setUsedCoupon(true)
            setTotalPrice(totalPriceNormal)
            //return true;
        } else{
            document.getElementById('err').innerHTML="Promo Code does not match.";
            document.getElementById('message').innerHTML="";
            let totalPriceNormal = (itemsPrice + shippingPrice + taxPrice).toFixed(2)
            setUsedCoupon(false)
            setTotalPrice(totalPriceNormal)
            //return false;

        }
    }


    const processToPayment = () => {

        const data = {
            itemsPrice: itemsPrice.toFixed(2),
            shippingPrice,
            taxPrice,
            totalPrice,
            usedCoupon

        }

        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        history.push('/payment-guess')
    }

    return (
        <Layout>
        <Fragment>

            <MetaData title={'CONFIRM ORDER'} />

            <div className="go-back">
                <Link to="/shipping-guess" className="back-link-checkout"><i class="fa fa-arrow-left"></i> Go Back To Shipping</Link>
            </div>

            <CheckoutSteps shipping confirmOrder />

        <div className='full-page'> 
            <div className='cart-page'>
            <div className=" cart-flex d-flex justify-content-between">
                <div className="col-12 col-lg-8  order-confirm">

                    <h4 className="mb-3">Shipping Info</h4>
                    <p><b>Type:</b> Guess Checkout</p>
                    <p><b>Name:</b> {shippingInfo.fullNameGuess}</p>
                    <p><b>Email:</b> {shippingInfo.emailGuess}</p>
                    <p><b>Phone:</b> {shippingInfo.phoneNo}</p>
                    <p className="mb-4"><b>Address:</b> {`${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.postalCode}`}, United States</p>
                    {/* <p className="mb-4"><b>Address:</b> {`${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.postalCode}, ${shippingInfo.country}`}</p> */}

                    <hr />
                    <h4 className="mt-4">Your Cart Items:</h4>

                    {cartItems.map(item => (
                        <Fragment>
                            <hr />
                            <div className="cart-item my-1" key={item.product}>
                                <div className="row">
                                    <div className="col-4 col-lg-2">
                                        <img src={item.image} alt={item.name} height="45" width="65" />
                                    </div>

                                    <div className="col-5 col-lg-6">
                                        <Link to={`/product/${item.product}`}>{item.name}</Link>
                                    </div>


                                    <div className="col-4 col-lg-4 mt-4 mt-lg-0">
                                        <p>{item.quantity} x ${item.price} = <b>${(item.quantity * item.price).toFixed(2)}</b></p>
                                    </div>

                                     <div className="col-4 col-lg-4 mt-4 mt-lg-0">
                                        <p>Size: {item.size}</p>
                                    </div>

                                </div>
                            </div>
                            <hr />
                        </Fragment>
                    ))}
                </div>

                <div className="col-12 col-lg-3 my-4">
                    <div id="order_summary">
                        <h4>Order Summary</h4>
                        
                        <form className="coupon-form" onsubmit="">
                        <p>Enter Promo Code</p>
                        <div className='coupon-code'>
                        <label><input type="text" name="coupon" id="in" class="coupon" title="Enter coupon" />
                        <span id="usernameError"></span></label>
                        <input type="button" className="coupon-btn" value="Submit" onClick={validate} /></div></form>
                        <span id="message"></span>
                        <span id="err"></span>

                        <hr />
                        <p>Subtotal:  <span className="order-summary-values">${itemsPrice}</span></p>
                        <p>Shipping: <span className="order-summary-values">${shippingPrice}</span></p>
                        {/* <p>Shipping: <span className="order-summary-values">Free Shipping</span></p> */}
                        <p>Tax:  <span className="order-summary-values">${taxPrice}</span></p>
                        <div className=" d-flex justify-content-start align-items-start "><p id="discount-amount"></p><span className="order-summary-values mt-1 ml-2" id="discount-amount-value"></span></div>

                        <hr />

                        <p>Total: <span className="order-summary-values">${totalPrice}</span></p>

                        <hr />
                        <button id="checkout_btn" className="btn btn-primary btn-block mb-4" onClick={processToPayment}>Proceed to Payment</button>
                        {/* <PayPalScriptProvider options={{ "client-id": "AYK7CZ5AcWKini1d82kgXGpAkClVloWSWVXXMv3O34X2JGMZ3tVzEEjyyhFUk2WnpEE7mOckZSAcION_" }}>
                            <PayPalButtons
                                //disabled={isDisabled}
                                fundingSource={FUNDING.PAYPAL} 
                                //style={{ height: 25 }}
                                //style={{ layout: "horizontal" }}
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        //intent: "CAPTURE",
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: totalPrice,
                                                },
                                            },
                                        ],
                                    });
                                }}
                                onApprove={onApprove}
                                onError={(err) => {
                                    //console.error(err);
                                   // return alert("Transaction was unsuccessful.");
                                  }}
                            />
                        </PayPalScriptProvider> */}
                    </div>
                </div>
            </div>
            </div>
        </div>
        </Fragment>
    </Layout>
    )
}

export default ConfirmOrderGuess