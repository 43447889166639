import React, { Fragment } from 'react'
import Layout from '../../layout/layout'
import { Link } from 'react-router-dom';

import MetaData from '../../layout/MetaData'
import '../../cart/Checkout.scss'

const Cerrado = () => {

    return (
        <Layout>
        <Fragment>

            <MetaData title={'Brazil Single Origin'} />

            <div className="go-back">
                <Link to="/product/brewingengineersbrazilcoffee" className="back-link-checkout"><i class="fa fa-arrow-left"></i> Go Back To Product</Link>
            </div>

            <div className='full-page'> 
            <div className='about-form'>
            <h2 className="mb-3 pl-5 pr-5">BRAZIL</h2>
                <p className="pl-5 pr-5">Coffee production in Brazil is responsible for about a third of all 
                coffee, making Brazil by far the world’s largest producer, a position the country has 
                held for the last 150 years. Coffee plantations, covering some 10,000 sq mi, 
                are mainly located in the southeastern states of Minas Gerais, Sao Paulo, 
                and Parana where the environment and climate provide ideal growing conditions.
                </p>

                <p className="pl-5 pr-5">The crop first arrived in Brazil in the 18th century and 
                the country had become the dominant producer by the 1840s. Brazilian coffee 
                prospered since the early 19th century, when Italian immigrants came to work in the 
                coffee plantations. Production as a share of world coffee output peaked in the 
                1920s but has declined since the 1950s due to increased global production.
                </p>
                <h2 className="mb-3 pl-5 pr-5">CERRADO</h2>
                <p className="pl-5 pr-5">Cerrado is a region in the state of Minas Gerais, which is now the largest 
                coffee-producing state in Brazil. Coffee has been a major crop in this region since the 1980’s 
                mainly because of the devastating black frost of 1975 that forced 
                growers from the Parana region to relocate north to Cerrado and other 
                areas of Minas Gerais. Today, coffee is produced by over 4,500 growers on 
                175,000 hectares of farmland with yields of approximately 5.5 to 6.0 million 
                bags per year. The coffee is grown in rich soil that the natives call “Terra Roxa” or 
                “Red Earth” and other factors such as consistent rains, high daytime temperatures, 
                and dry winters combine to make the Cerrado region an ideal area in Brazil for producing coffee.
                </p>
            </div>
            </div>

        </Fragment>
    </Layout>
    )
}

export default Cerrado