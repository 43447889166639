import axios from 'axios';

import {
    ALL_RECIPES_REQUEST,
    ALL_RECIPES_SUCCESS,
    ALL_RECIPES_FAIL,
    ADMIN_RECIPES_REQUEST,
    ADMIN_RECIPES_SUCCESS,
    ADMIN_RECIPES_FAIL,
    NEW_RECIPE_REQUEST,
    NEW_RECIPE_SUCCESS,
    NEW_RECIPE_FAIL,
    DELETE_RECIPE_REQUEST,
    DELETE_RECIPE_SUCCESS,
    DELETE_RECIPE_FAIL,
    UPDATE_RECIPE_REQUEST,
    UPDATE_RECIPE_SUCCESS,
    UPDATE_RECIPE_FAIL,
    RECIPE_DETAILS_REQUEST,
    RECIPE_DETAILS_SUCCESS,
    RECIPE_DETAILS_FAIL,
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_FAIL,
    CLEAR_ERRORS

} from '../constants/recipeConstants'

export const getRecipes = (keyword = '',currentPage = 1,price, category,rating = 0) => async (dispatch) => {
//export const getProducts = (keyword = '', currentPage = 1, price, category, rating = 0) => async (dispatch) => {
    try {

        dispatch({ type: ALL_RECIPES_REQUEST })
        let link = `/api/v1/recipes?page=${currentPage}`

        //let link = `/api/v1/recipes?keyword=${keyword}&page=${currentPage}&price[lte]=${price[1]}&price[gte]=${price[0]}`
        //let link = `/api/v1/recipes?keyword=${keyword}&page=${currentPage}&price[lte]=${price[1]}&price[gte]=${price[0]}&ratings[gte]=${rating}`

        if(category){
            link = `/api/v1/recipes?page=${currentPage}`
            //link = `/api/v1/recipes?keyword=${keyword}&page=${currentPage}&price[lte]=${price[1]}&price[gte]=${price[0]}&category=${category}`
        }

        const { data } = await axios.get(link)

        dispatch({
            type: ALL_RECIPES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_RECIPES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const newRecipe = (recipeData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_RECIPE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/v1/recipe/new`, recipeData, config)
        dispatch({
            type: NEW_RECIPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_RECIPE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const cloneRecipe = (id,recipeData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_RECIPE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(`/api/v1/recipe/clone/${id}`, recipeData, config)
        dispatch({
            type: NEW_RECIPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_RECIPE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete recipe (Admin)
export const deleteRecipe = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_RECIPE_REQUEST })

        const { data } = await axios.delete(`/api/v1/recipe/delete/${id}`)

        dispatch({
            type: DELETE_RECIPE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_RECIPE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update recipe (ADMIN)
export const updateRecipe = (id, recipeData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_RECIPE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/recipe/edit/${id}`, recipeData, config)

        dispatch({
            type: UPDATE_RECIPE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_RECIPE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getRecipeDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: RECIPE_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/v1/recipe/${id}`)

        dispatch({
            type: RECIPE_DETAILS_SUCCESS,
            payload: data.recipe
        })

    } catch (error) {
        dispatch({
            type: RECIPE_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const newReview = (reviewData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_REVIEW_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/review`, reviewData, config)

        dispatch({
            type: NEW_REVIEW_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: NEW_REVIEW_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminRecipes = () => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_RECIPES_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/recipes`)

        dispatch({
            type: ADMIN_RECIPES_SUCCESS,
            payload: data.recipes
        })

    } catch (error) {

        dispatch({
            type: ADMIN_RECIPES_FAIL,
            payload: error.response.data.message
        })
    }
}


// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}