import React,{useEffect, Fragment, useState} from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { getProductDetails, clearErrors } from '../../actions/productActions';
import { useAlert } from 'react-alert';
import { addItemToCart } from '../../actions/cartActions';
import MetaData from '../layout/MetaData';
import ListReviews from '../review/ListReviews';
import { NEW_REVIEW_RESET } from '../../constants/productConstants';
import LayoutTwo from '../layout/layoutTwo';
import Loading from '../pages/loading/loading'
import './ProductDetails.styles.scss';

const ProductDetails = ({ match }) => {

    const [quantity, setQuantity] = useState(1)
    //const [rating, setRating] = useState(0);
    const [size, setSize] = useState('');
    //const [comment, setComment] = useState('');

    const dispatch = useDispatch();
    const alert = useAlert();

    const {loading, error, product} = useSelector(state => state.productDetails)
    //const { user } = useSelector(state => state.auth)
    const { error: reviewError, success } = useSelector(state => state.newReview)


    useEffect(() => {
        dispatch(getProductDetails(match.params.id))

        if(error){
            alert.error(error);
            dispatch(clearErrors())
        }
        
        if (reviewError) {
            alert.error(reviewError);
            dispatch(clearErrors())
        }

        if (success) {
            alert.success('Reivew posted successfully')
            dispatch({ type: NEW_REVIEW_RESET })
        }

    }, [dispatch, alert, error, reviewError, match.params.id, success])

    const addToCart = () => {
        dispatch(addItemToCart(match.params.id, quantity,size));
        //dispatch(addItemToCart(match.params.id, size));

        alert.success('Item Added to Cart')


        //const formData = new FormData();

        //formData.append('_id',match.params.id);
        //formData.set('quantity',quantity);
        //formData.set('size', size);
        //alert.success('Item Added to Cart')

        //dispatch(addItemToCart(formData));
    }

    

    const increaseQty = () => {
        const count = document.querySelector('.count')

        if (count.valueAsNumber >= product.stock) return;

        const qty = count.valueAsNumber + 1;
        setQuantity(qty)
    }

    const decreaseQty = () => {

        const count = document.querySelector('.count')

        if (count.valueAsNumber <= 1) return;

        const qty = count.valueAsNumber - 1;
        setQuantity(qty)

    }

   
    const sizes = [
        '',
        'Small',
        'Medium',
        'Large',
    ]

    const sizesTwo = [
        '',
        '8oz',
    ]


    return(
        <LayoutTwo>
        <Fragment>
        {loading ? <Loading/> : ( 
            <Fragment>
            <MetaData title={product.name} />
            <div className='full-page'>
            <div className='cart-page'>
                <div className=" f-flex justify-content-around product-content">
                    <div className="col-12 col-lg-5 img-fluid product-image" id="product_image">
                       <Carousel pause='hover'>
                            {product.images && product.images.map(image => (
                                <Carousel.Item key={image.public_id}>
                                    <img className="d-block w-100" src={image.url} alt={product.title}/>
                                </Carousel.Item>
                            ))}
                       </Carousel>
                    </div>

                    <div className="product-details">
                        <div className="product-box">
                            <div className="product-text">
                                <h5>{product.name}</h5>
                                <p id="product_id">Product # {product._id}</p>
                                <hr/>

                                <p className="product-price">${product.price}</p>
                                <div className="stockCounter d-inline">
                                    <span className="btn  minus" onClick={decreaseQty}>-</span>

                                    <input id='quantity' type="number" className=" count d-inline" value={quantity} readOnly />

                                    <span className="btn  plus" onClick={increaseQty}>+</span>
                                </div>
                                

                                <hr/>

                                <p>Status: <span id="stock_status" className={product.stock > 0 ? 'greenColor' : 'redColor'}>{product.stock > 0 ? 'In Stock' : 'Out Of Stock'}</span></p>

                                {product.category == 'Food' &&
                                <div className="">
                                    <label htmlFor="size_field_two">Size</label>
                                    <select className="size-input" id="size_field_two" value={size} onChange={(e) => setSize(e.target.value)} required>
                                        {sizesTwo.map(size => (
                                            <option key={size} value={size} >{size}</option>
                                        ))}
                                    </select>
                                </div>
                                }

                                {product.category == 'Clothes/Shoes' &&
                                <div className="">
                                    <label htmlFor="size_field">Size</label>
                                    <select className="size-input" id="size_field" value={size} onChange={(e) => setSize(e.target.value)} required>
                                        {sizes.map(size => (
                                            <option key={size} value={size} >{size}</option>
                                        ))}

                                    </select>
                                </div>
                                }
                                <hr/>


                                <h5 className="mt-2">Description:</h5>
                                {product.name == 'BREWING ENGINEERS COFFEE' &&
                                    <div>
                                        {/*<p className='mb-0'>NOTES</p> */}
                                        <p>cherry, lemon, milk chocolate</p>
                                        <p>
                                            Growing Altitude—1520-1650M Arabica Variety—Caturra, Typica, Castillo & Colombia Harvest 
                                            Period—September- January Milling Process—Washed Producers pick and process coffee at their 
                                            own micro-wet mills and then dry their own coffee.
                                        </p>
                                    </div>
                                }
                                {product.name == 'BREWING ENGINEERS COSTA RICA COFFEE' &&
                                    <div>
                                        {/*<p className='mb-0'>NOTES</p> */}
                                        <p>blueberry, honey, and raspberry</p>
                                        <p>
                                        Honey processed coffee is unique in that while the skin and pulp 
                                        are removed from the cherry, the mucilage, a sugary, sticky outer 
                                        layer, is retained during the drying stage. Honey process 
                                        is significantly less acidic than washed or natural/sun dried 
                                        coffees and have much more character and sweetness than traditional 
                                        fully washed coffees.<Link className="back-link-checkout" to='/yellow-honey-process'>Read More...</Link>
                                        </p>
                                    </div>
                                }
                                {product.name == 'BREWING ENGINEERS BRAZIL COFFEE' &&
                                    <div>
                                        {/*<p className='mb-0'>NOTES</p> */}
                                        <p>Chocolate, nutty, sweet, and caramel</p>
                                        <p>
                                        Cerrado is a region in the state of Minas Gerais, which is now the largest 
                                        coffee-producing state in Brazil. Coffee has been a major crop in this region since the 1980’s 
                                        mainly because of the devastating black frost of 1975 that forced 
                                        growers from the Parana region to relocate north to Cerrado and other 
                                        areas of Minas Gerais.
                                        <Link className="back-link-checkout" to='/cerrado-coffee'>Read More...</Link>
                                        </p>
                                    </div>
                                }
                                {product.name == 'SUBSCRIPTION - BREWING ENGINEERS BRAZIL COFFEE' &&
                                    <div>
                                        <p><b className='mb-0'>COMING SOON</b></p>
                                        <p>Chocolate, nutty, sweet, and caramel</p>
                                        <p>
                                        Cerrado is a region in the state of Minas Gerais, which is now the largest 
                                        coffee-producing state in Brazil
                                        <Link className="back-link-checkout" to='/cerrado-coffee'>Read More...</Link>
                                        </p>
                                    </div>
                                }
                                {product.name == 'BREWING ENGINEERS T-SHIRT' &&
                                    <p> Soft cotton black t-shirt front BREWING ENGINEERS logo</p>
                                }

                                {/* <p>{product.description}</p> */}
                                {/* <input value={size} onChange={(e) => setSize(e.target.value)}></input> */}

                                  
                            </div>
                            <button type="button" id="cart_btn" disabled={product.stock === 0 || size == '' || sizes == '' && product.category == 'Clothes/Shoes'  } onClick={addToCart}  className="product-button">Add to Cart</button>

                    </div>
                </div>
            </div>
            </div>
            
            </div>
            {product.reviews && product.reviews.length > 0 && (
                        <ListReviews reviews={product.reviews} />
                    )}
            </Fragment>
        )}
        </Fragment>
        </LayoutTwo>
    )
}

export default ProductDetails