import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import {Link} from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css'

//Components
import ScrollForMore from "./ScrollForMore";
//Ease
const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const firstName = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: -1,
    },
  },
};

const lastName = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const thirdName = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: 2,
    },
  },
};

const letter = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { duration: 1, ...transition },
  },
};

const Model = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 3], [1, 0]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }

    Aos.init({duration: 2000})
  }, [canScroll]);

  return (
    <motion.div
      onAnimationComplete={() => setCanScroll(true)}
      className='single'
      initial='initial'
      animate='animate'
      exit='exit'>
      <div className='container fluid'>
        <div className=' center top-row'>
          <div className='top'>
            
            <motion.div className='model'>
              <motion.span className='first' variants={firstName}>
                <motion.span variants={letter}>c</motion.span>
                <motion.span variants={letter}>r</motion.span>
                <motion.span variants={letter}>a</motion.span>
                <motion.span variants={letter}>f</motion.span>
                <motion.span variants={letter}>t</motion.span>
              </motion.span>
              <motion.span className='last' variants={lastName}>
                <motion.span variants={letter}>b</motion.span>
                <motion.span variants={letter}>e</motion.span>
                <motion.span variants={letter}>a</motion.span>
                <motion.span variants={letter}>u</motion.span>
                <motion.span variants={letter}>t</motion.span>
                <motion.span variants={letter}>i</motion.span>
                <motion.span variants={letter}>f</motion.span>
                <motion.span variants={letter}>u</motion.span>
                <motion.span variants={letter}>l</motion.span>
              </motion.span>
              <motion.span className='third' variants={thirdName}>
                <motion.span variants={letter}>c</motion.span>
                <motion.span variants={letter}>o</motion.span>
                <motion.span variants={letter}>f</motion.span>
                <motion.span variants={letter}>f</motion.span>
                <motion.span variants={letter}>e</motion.span>
                <motion.span variants={letter}>e</motion.span>
              </motion.span>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 1.2, ...transition },
              }}
              className='details'>
              <div className='location'>
                <span>
                Our mission is to impact communities for good and pour redeeming goodness into every cup.
                </span>
              </div>
              <div className='mua'></div>
            </motion.div>
          </div>
        </div>
        <div className=' bottom-row'>
          <div className='bottom'>
            <motion.div className='image-container-single'>
              <motion.div
                initial={{
                  y: "-50%",
                  width: imageDetails.width,
                  height: imageDetails.height,
                }}
                animate={{
                  y: 0,
                  width: "100%",
                  height: window.innerWidth > 1440 ? 800 : 350,
                  transition: { delay: 0.2, ...transition },
                }}
                className='thumbnail-single'>
                <motion.div
                  className='frame-single'
                  whileHover='hover'
                  transition={transition}>
                  <motion.img
                    src="/images/barista0002-01.png"
                    alt='an image'
                    style={{ scale: scale }}
                    initial={{ scale: 1.0 }}
                    animate={{
                      transition: { delay: 0.2, ...transition },
                      y: window.innerWidth > 1440 ? -600 : -500,
                    }}
                  />
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
          <ScrollForMore />
        </div>
      </div>
      {/* <div className='detailed-information'> */}
        {/* <div className='container'> */}
        <div className='home-section'>
      <h1>THE FUTURE OF BREWING COFFEE</h1>
      <div className='home-section-para'>
        <p>BREWING ENGINEERS believes there is <span className='home-section-highlight'>a better way to brew coffee.</span></p>
        <p><span className='home-section-highlight'>Time, weight, and ratio</span> is the very <span className='home-section-highlight'>foundation of a perfect cup</span>, with Recipes you will be able to achive and perfect your daily crafted cup of coffee.</p>
        <p>As we miss the ambiance and <Link to='/playlist'>playlist</Link> playing in the background at a coffee shop people have shift to brewing at home.</p>
        <p>With more time at home and out of the office, we brewing engineers have more space to <span className='home-section-highlight'>repeat and refine a target recipe.</span></p>
      </div>
    </div>

{/* <AnimatedBg> */}
  {/* <div style={{ height: '900px' }} /> */}
        <div className=''>
          <div className="coffeebag">
            {/* <div className="coffeebag-text">
              <div data-aos="fade-right"  className="in-left">
                <p>that average 2.5 hectares in size.</p>
                <p>at their own micro-wet mills and then</p>
                <p>elevated tables inside solar dryers</p>
                <p>nearly continuos rainy season.</p>
              </div>
              <div data-aos="fade-left" className="in-right">
                <p>Our Coffee is cultivated on farms</p>
                <p>Producers pick and process coffee</p>  
                <p>dry their own coffee, typically on </p>
                <p>that provide protection from the</p>
              </div>
            </div> */}
            <div className="coffeebag-img">
                <img src='/images/showing-product-01.png' alt='coffeebag'/>
            </div>
            <div className="coffeebag-text-mobile">
              <p className="in-right-mobile">
                Our Coffee is cultivated on farms that average 2.5 hectares in size.
              </p>
              <p className="in-right-mobile">Producers pick and process coffee at their own micro-wet mills and then
              </p>
              <p className="in-right-mobile">dry their own coffee,typically on elevated tables inside solar dryers
              </p>
              <p className="in-right-mobile">
                that provide protection from the nearly continuos rainy season.
              </p>
            </div>
            <div className='coffeebag-button'>
                  <Link id="login_btn" className='coffeebag-button-link' to='/shop'>SHOP NOW</Link>
            </div>
          </div>
        </div>

  {/* <div style={{ height: '900px' }} />
  <Transition height="400px" from="#0D47A1" to="#388E3C" /> */}
  {/* <div style={{ height: '900px' }} /> */}
  {/* <Transition height="150px" from="#FFFFFF" to="#07080A" position={0.75}>
  </Transition>
<Transition height="300px" from="#07080A" to="#FFFFFF" position={0.77}>
              
</Transition>
  <div style={{ height: '200px' }} />
</AnimatedBg> */}
        {/* </div> */}
      {/* </div> */}
    </motion.div>
  );
};

export default Model;