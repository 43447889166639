import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'
import  Loading from '../pages/loading/loading'
import './Recipe.styles.scss'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getRecipes, deleteRecipe, clearErrors } from '../../actions/recipeActions'
import { DELETE_RECIPE_RESET } from '../../constants/recipeConstants'
// import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";


const RecipeUser = ({recipe, col,history}) => {


    const alert = useAlert();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)

    const { loading, error, recipes} = useSelector(state => state.recipes);
    const {error: deleteError, isDeleted } = useSelector(state => state.recipe)
     useEffect(() => {
        //dispatch(getRecipes());

    if (error) {
        alert.error(error);
        dispatch(clearErrors())
    }

    if (deleteError) {
        alert.error(deleteError);
        dispatch(clearErrors())
    }

    if (isDeleted) {
        alert.success('Recipe deleted successfully');
        //history.push('/');
        window.location.reload(false);
        dispatch({ type: DELETE_RECIPE_RESET })
    }

     }, [dispatch, alert, error, history, isDeleted,deleteError])

    const deleteRecipeHandler = (id) => {
        dispatch(deleteRecipe(id))
    }

    // //3 dot dropdown
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <i class="fas fa-ellipsis-v"></i>
          {children}
      
        </a>
      ));
      console.log(user)


    return(
        <>
            {user && user._id == recipe.user &&
            <div className="recipe-card">
                {loading ? <Loading/> : (
                    <div className="recipe-card-body">   
                        <div className="recipe-card-left">
                            {/* <img className="recipe-card-img" src={recipe.images[0].url} alt={recipe.name}/> */}
                            {recipe.method == "Chemex" &&
                                <img className="recipe-card-img" src='/images/chemex_icon.png' alt={recipe.name}/>
                            }
                            {recipe.method == "AeroPress" &&
                                <img className="recipe-card-img" src='/images/aeropress_icon.png' alt={recipe.name}/>
                            }
                            {recipe.method == "V60" &&
                                <img className="recipe-card-img" src='/images/v60_icon-300x300.png' alt={recipe.name}/>
                            }
                            {recipe.method == "Turkish" &&
                                <img className="recipe-card-img" src='/images/chemex_icon.png' alt={recipe.name}/>
                            }
                            {recipe.method == "Siphon" &&
                                <span>Image Coming Soon</span>
                            }
                            {recipe.method == "Cold Brew" &&
                                <span>Image Coming Soon</span>
                            }
                            {recipe.method == "French Press" &&
                                <span>Image Coming Soon</span>
                            }
                            {recipe.method == "Coffee Maker" &&
                                <span>Image Coming Soon</span>
                            }
                        </div>
                        <div className="recipe-card-middle">
                            <Link to={`/recipe/${recipe._id}`}>
                                <div className="recipe-card-title d-flex flex-row justify-content-right align-items-right text-light">
                                    <p>{recipe.name}</p>
                                    {/* <Link className="card-title-link" to={`/recipe/${recipe._id}`}>{recipe.name}</Link> */}
                                </div>
                            </Link>
                            <div className="recipe-card-details">
                                <img src='/images/coffeebean.png' alt='coffeebean'/><p>{recipe.coffee}</p>
                                <img src='/images/waterdrop.png' alt='waterdrop'/><p>{recipe.water}</p>
                                <img src='/images/percent.png' alt='percent'/><p>{recipe.ratio}</p>
                            </div>
                        </div>
                        {user && user._id == recipe.user &&
                            <div className="recipe-card-right">
                                <Dropdown >
                                    <Dropdown.Toggle as={CustomToggle}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu size="sm" title="" className="bg-dark"> 
                                    <Dropdown.Header>Options</Dropdown.Header>
                                    <Dropdown.Item className="bg-dark">
                                        <Link to={`/recipe/update/${recipe._id}`}><p>Edit</p>
                                        
                                        </Link> 
                                    </Dropdown.Item>
                                    <Dropdown.Item className="bg-dark">
                                        <p onClick={() => deleteRecipeHandler(recipe._id)}>   
                                            Delete
                                        </p>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="bg-dark">
                                        <Link to={`/recipe/clone/${recipe._id}`}><p>Clone</p>
                                        
                                        </Link> 
                                    </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }     
                    </div>    
                )}
            </div>
            }
        </> 
              
    )

}
export default RecipeUser;


{/*<Logged isLoggedIn={false} />,
    if(user == recipe.user ){
<div className="recipe-card-right">
    <Link to={`/recipe/update/${recipe._id}`} className="btn btn-primary py-1 px-2">
        <i className="fa fa-pencil"></i>
    </Link> 
    <button className="btn btn-danger py-1 px-2 ml-2" onClick={() => deleteRecipeHandler(recipe._id)}>
        <i className="fa fa-trash"></i>
    </button>
</div>
}
<div className="ratings mt-auto">
<div className="rating-outer">
<div className="rating-inner" style={{ width: `${(product.ratings / 5)* 100}%`}}></div>
</div>
<span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
</div>
<p className="card-text">${.price}</p>
<Link to={`/product/${product._id}`} className=" btn-block">View Details</Link> */}