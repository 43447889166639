import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { logout } from '../../actions/userActions'
// import SignOutButton from '../SignOut';
// import { AuthUserContext } from '../Session';
// import logo from '../../images/Origami_bird-512.png'
// import cart from '../../images/images (1).png'

import styled from 'styled-components';


const StyledMenu = styled.nav`
@media screen and (min-width: 1024px) {
    display:none;
}
@media screen and (max-width: 1024px) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgb(238,174,202);
  background: linear-gradient(317deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 50%);
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  width: 100%;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1050
  }
  @media (max-width: 576px){
      width: 100%;
  }
    @media (max-width: 576px) {
      font-size: .9rem;
      text-align: center;
    }
    &:hover {
      color: #343078;
    }
  }
}
`


const Menu = ({ open }) => {

  const alert = useAlert();
    const dispatch = useDispatch();

    const { user, loading } = useSelector(state => state.auth)
    const { cartItems } = useSelector(state => state.cart)

    const logoutHandler = () => {
        dispatch(logout());
        alert.success('Logged out successfully.')
    }

  return (
    <StyledMenu open={open}>

      <div className="col-12 col-md-3 nav-account">

      {user ? (
          <div>
              {user && user.role === 'admin' && (
                      <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
              )}
          <div className=" d-inline">
              <Link to="/profiletabs" className='mobile-profile-nav' type="button" aria-haspopup="true">

                  <figure className="avatar avatar-nav">
                      <img
                          src={user.avatar && user.avatar.url}
                          alt={user && user.name}
                          className="rounded-circle"
                      />
                  </figure>
                  <span>{user && user.name}</span>
              </Link>
          </div>
          
          <Link id="login_btn" to="/" onClick={logoutHandler}>
              Logout
          </Link>
          
      </div>

      ) : !loading && <Link to="/login" className=" ml-4" id="login_btn">LOGIN</Link>}
    

    <ul className="nav-pages">
      <li>
          <Link className='nav-pages-link' to='/'>
              HOME
          </Link>
      </li>
      <li>
          <Link className='nav-pages-link' to='/shop'>
              SHOP
          </Link>
      </li>
      <li>
          <Link className='nav-pages-link' to='/recipes'>
                RECIPES
          </Link>
      </li>
      <li>
          <Link className='nav-pages-link' to='/brew'>
              BREW
          </Link>
      </li>
      <li>
          <Link className='nav-pages-link' to='/about'>
              ABOUT
          </Link>
      </li>
    </ul>

    <Link to='/cart' style={{textDecoration:'none'}} className='cart-container-mobile'>
          {/* <span id="cart" className="ml-3">CART</span> */}
          <img src="/images/shopping-cart.png" alt=""/>
          <span className="cart-count" id="cart_count">{cartItems.length}</span>
      </Link>
    </div>
    
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
@media screen and (min-width: 1024px) {
    display:none;
}
@media screen and (max-width: 1024px){
  position: absolute;
  top: 2%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 2000;
  
  &:focus {
    outline: none;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? '#434549' : '#434549'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }
    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
}
`

const Burger = ({ open, setOpen }) => {
  return (
    <div className="mobile-navbar">
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
  
      <div className="navbar-brand-mobile">  
          <Link className='mobile-logo' to='/'>             
              <img src="/images/logo.png" alt="logo"/>
              Beta
          </Link>
          
      </div>
    </div>
  )
}


const MobileNav = () => {
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();
  return (
    <div>
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </div>
  )  
}


export default MobileNav;

const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  },
  [ref, handler],
  );
};