import React, {Component} from 'react';
//import espressoIcon from '../../../assets/2_espresso.png'
import { Link } from 'react-router-dom';
import MetaData from '../../layout/MetaData';
import EspressoTab from './tabs/espresso-tab'
import './brew.styles.scss'
import Layout from '../../layout/layout';


class EspressoPage extends Component{
    render(){
        return(
            <Layout>
            <MetaData title={"ESPRESSO"} />
            <div className="method-page">
                <div className="method-top">
                    <div className="method-img">
                        <img src='/images/2_espresso.png' alt="espresso"/>
                    </div>
                    <div className="method-description">
                        <h1>Espresso</h1>
                        <p>For coffee purists, espresso is the quintessential 
                        coffee preparation – rich, aromatic and velvety all 
                        at once; a natural layer of crema on top belying a 
                        full-bodied, yet deftly balanced liquid below. When 
                        ideally realized, a small miracle of chemistry and 
                        physics: science and art gliding together on air.</p>

                        <p>Espresso’s authentic formula is clear and basic, 
                        its proper execution a matter of training, experience
                        and natural talent.  A jet of hot water at 88°-93°C
                        (190°-200°F) passes under a pressure of nine or more 
                        atmospheres through a seven-gram (.25 oz) cake-like 
                        layer of ground and tamped coffee. Done right, the 
                        result is a concentrate of not more than 30 ml (one oz) 
                        of pure sensorial pleasure.</p>
                    </div>
                </div>
                <div className="method-middle">
                    <div className="method-tabs">
                            <EspressoTab />
                    </div>
                    <div className="method-requirements">
                        <h1>BEFORE YOU BREW</h1>
                        <p>Authentic espresso preparation ideally starts 
                        by freshly grinding beans perfected for this 
                        purest of methods.  The art of the barista is 
                        to calibrate the grind, expertly tamp just the 
                        right amount of coffee into the filter, then keep 
                        close watch on time, temperature and pressure. 
                        Even today’s advanced, automatic machines require 
                        proper vigilance; perfection hangs in the balance.
                        </p>

                        <p></p>
                    </div>
                </div>
                <div className="method-bottom">
                    <Link className="method-button" to='/timer/espresso'>BREW ESPRESSO TIMER</Link>
                </div>
            </div>
            </Layout>
        )
    }
}

const EspressoBox = () => (
    <div className="method-box"><Link className="method-box-link"  to='/espresso'>
        <img src='/images/2_espresso.png' alt=""/>
        <h3>Espresso</h3>
        <p>Pressure</p>
        <p>fine Grind</p>
        <p>20-30 sec</p>
    </Link></div>
);

export default EspressoPage;
export {EspressoBox};