import React, { Fragment } from 'react';
import Layout from '../../layout/layout';
import MetaData from '../../layout/MetaData';
import Prototype from './prototype';

import "./Home.scss";
import Model from './HomeBanner'

const Home = () => {
 

      const imageDetails = {
        width: 524,
        height: 650,
      };
  
    return (
        <Layout>
        <Fragment>
            <MetaData title={"HOME"} />
            <div className='banner'>

                <Model imageDetails={imageDetails} />
               
            </div>
            <Prototype/>
        </Fragment>
        </Layout>
    )
}

export default Home;