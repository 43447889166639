import React, {useEffect, Fragment, useState,Component} from 'react';
import { Link } from 'react-router-dom'
import MetaData from './layout/MetaData';
import Pagination from 'react-js-pagination';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Layout from './layout/layout';
import './RecipeList.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import {getRecipes } from '../actions/recipeActions';
import Recipe from './recipe/Recipe';
import RecipeUser from './recipe/RecipeUser';
import Loading from './pages/loading/loading';
import './RecipeTabs.styles.scss';

class Tabs extends Component {
    constructor(props) {
      super();
      this.state = {
        active: 2
      }
    }
    
    select = (i) => {
      let _this = this;
      return function() {
        _this.setState({
          active: i
        });
      }
    }
    
    renderTabs = () => {
      return React.Children.map(this.props.children, (item, i) => {
        if (i%2 === 0) {
          let active = this.state.active === i ? 'active' : '';
          return <button onClick={this.select(i)} className={`${active} tab`}>{item}</button>;
        }
      });
    }
    
    renderContent() {
      return React.Children.map(this.props.children, (item, i) => {
        if (i-1 === this.state.active) {
          return <div className='content'>{item}</div>;
        } else {
          return;
        }
      });
    }
    
    render() {
      return (
        <div className="tabs">
          {this.renderTabs()}
          {this.renderContent()}
        </div>
      );
    }
  }


const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range)

const RecipeList = ({match}) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [price, setPrice] = useState([1, 1000])
    const [category, setCategory] = useState('')
    const [rating, setRating] = useState(0)


    const alert = useAlert();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)

    const {loading, recipes, error, recipesCount, resPerPage, filteredRecipesCount } = useSelector(state => state.recipes)


    const keyword = match.params.keyword

    useEffect(() => {
        if (error) {
            return alert.error(error)
        }

        dispatch(getRecipes(keyword, currentPage, price, category, rating));


    }, [dispatch, alert, error, keyword, currentPage, price, category, rating])

    function setCurrentPageNo(pageNumber) {
        setCurrentPage(pageNumber)
    }

    let count = recipesCount;
    if (keyword) {
        count = filteredRecipesCount
    }

    return(
        <Fragment>
            {loading ? <Loading/> : (
                <Fragment>
                <MetaData title={"RECIPES"} />
                
                <div className='recipe-list'>
                    <div className="go-back-lab">
                        <Link to="/" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Home</Link>
                        <div className='header-recipe-logo'>
                            <img src="/images/logo.png" alt="logo"/> <span> Lab</span>
                        </div>
                    </div> 
                    <div className="recipe-page">
            
                    {/* <main className="account-page"> */}
                        <Tabs>
                            All Recipes
                            <span>
                            <div className="recipe-box">
                            {keyword ? (
                                <Fragment>
                                    <div className="col-6 col-md-9 list">
                                        <div>
                                            {recipes.map(recipe => (
                                                <Recipe key={recipe._id} recipe={recipe} col={4} />
                                                ))}
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                
                                    <div className=" list-container">
                                        <div className="list">
                                            {recipes.map(recipe => (
                                                    <Recipe key={recipe._id} recipe={recipe} col={3} />
                                            ))}
                                        </div>
                                        
                                    </div>
                            
                                )}

                            {resPerPage <= count && (
                                <div className="d-flex justify-content-center mt-5">
                                    <Pagination
                                        activePage={currentPage}
                                        itemsCountPerPage={resPerPage}
                                        totalItemsCount={recipesCount}
                                        onChange={setCurrentPageNo}
                                        nextPageText={'Next'}
                                        prevPageText={'Prev'}
                                        firstPageText={'First'}
                                        lastPageText={'Last'}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            )}
                            </div>
                                
                            </span>
                            Featured Recipes
                            <span>
                            <div className="recipe-box">
                            {keyword ? (
                                <Fragment>
                                    <div className="col-6 col-md-9 list">
                                        <div>
                                            {recipes.map(recipe => (
                                                <Recipe key={recipe._id} recipe={recipe} col={4} />
                                                ))}
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                
                                    <div className=" list-container">
                                        <div className="list">
                                            {recipes.map(recipe => (
                                                    <Recipe key={recipe._id} recipe={recipe} col={3} />
                                            ))}
                                        </div>
                                        
                                    </div>
                            
                                )}

                            {resPerPage <= count && (
                                <div className="d-flex justify-content-center mt-5">
                                    <Pagination
                                        activePage={currentPage}
                                        itemsCountPerPage={resPerPage}
                                        totalItemsCount={recipesCount}
                                        onChange={setCurrentPageNo}
                                        nextPageText={'Next'}
                                        prevPageText={'Prev'}
                                        firstPageText={'First'}
                                        lastPageText={'Last'}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            )}
                            </div>
                               
                            </span>
                            My Recipes
                            <span>
                            <div className="recipe-box">
                            {keyword ? (
                                <Fragment>
                                    <div className="col-6 col-md-9 list">
                                        <div >
                                            {recipes.map(recipe => (
                                                    <RecipeUser key={recipe._id} recipe={recipe} col={4} />
                                                ))}
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                
                                    <div className=" list-container">
                                        <div className="list">
                                        
                                            {recipes.map(recipe => (
                                                <RecipeUser key={recipe._id} recipe={recipe} col={3} />                
                                            ))}
                                        </div>
                                        
                                    </div>
                            
                                )}

                                {resPerPage <= count && (
                                    <div className="d-flex justify-content-center mt-5">
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={resPerPage}
                                            totalItemsCount={recipesCount}
                                            onChange={setCurrentPageNo}
                                            nextPageText={'Next'}
                                            prevPageText={'Prev'}
                                            firstPageText={'First'}
                                            lastPageText={'Last'}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                )}
                            </div>
                            
                            </span>
                            
                        </Tabs>
                        {/* </main> */}

                        <div className="recipe-card-new">
                            <Link className='recipe-card-new-link' to="/new"><i className="recipe-card-new-link fa fa-plus"></i> CREATE</Link>
                            {/* <div className='recipe-card-new-link text-muted'><i className="recipe-card-new-link fa fa-plus text-muted"></i> CREATE</div> */}
                        </div>
                        
                    </div>
                </div>
            
                </Fragment>
            )}
        </Fragment>
    )
}

export default RecipeList;