import React, { Fragment } from 'react'
import Layout from './components/layout/layout'
import { Link } from 'react-router-dom';

import MetaData from './components/layout/MetaData'
import './components/cart/Checkout.scss'

const PrivatePolicy = () => {

    return (
        <Layout>
        <Fragment>

            <MetaData title={'Private Policy'} />

            <div className="go-back">
                <Link to="/" className="back-link-checkout"><i class="fa fa-arrow-left"></i> Go Back To Home Page</Link>
            </div>

            <div className='full-page'> 
            <div className='about-form'>
                <h2 className="mb-3 pl-5 pr-5">General information</h2>
                <p className="pl-5 pr-5">Welcome to our Privacy Policy page! When you use our web services, you trust us with your information. 
                    This Privacy Policy is meant to help you understand what data we collect, why we collect it, and what we do 
                    with it. When you share information with us, we can make our services even better for you. For instance, 
                    we will only show revelent information from our service, no 3rd party ads will be presented to you. We try to help you connect with people or to make sharing with 
                    others quicker and easier. As you use our services, we want you to be clear how we’re using information 
                    and the ways in which you can protect your privacy. This is important; we hope you will take time to read 
                    it carefully. Remember, you can find controls to manage your information and protect your privacy and 
                    security. We’ve tried to keep it as simple as possible.
                </p>
                <h2 className="mb-3 pl-5 pr-5">Information We Collect</h2>
                <p className="pl-5 pr-5">Our application collects data to operate effectively and provide you the best experiences with our services.
                     You provide some of this data directly, such as when you create a personal account. We get some of it 
                     by recording how you interact with our services by, for example, using technologies like cookies, 
                     and receiving error reports or usage data from software running on your device. We dont collect location data or card information 
                     unless you make a purchase. Cards will save securely if you make subscription purchase with our 3rd party provider payment processing stripe, paypal and apple. Location data will be saved to an order record which is tied to a logged in user.
                </p>
            </div>
            </div>

        </Fragment>
    </Layout>
    )
}

export default PrivatePolicy