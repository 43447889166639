import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import MetaData from '../layout/MetaData'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword, clearErrors } from '../../actions/userActions'

const NewPassword = ({ history, match }) => {

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, success } = useSelector(state => state.forgotPassword)

    useEffect(() => {

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (success) {
            alert.success('Password updated successfully')
            history.push('/login')
        }

    }, [dispatch, alert, error, success, history])

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set('password', password);
        formData.set('confirmPassword', confirmPassword);

        dispatch(resetPassword(match.params.token, formData))
    }

    return (
        <Fragment>

            <MetaData title={'NEW PASSWORD RESET'} />

            <div className="login-page">
                <div className="go-back">
                    <Link to="/" className="back-link"><i class="fa fa-arrow-left"></i> Go Back To Homepage</Link>
                </div>
                <div className="login">
                    <form className="login-form" onSubmit={submitHandler}>
                        <h1 className="mb-3">NEW PASSWORD</h1>

                        <div className="group">
                            {/* <label htmlFor="password_field">Password</label> */}
                            <input
                                type="password"
                                id="password_field"
                                className="login-input"
                                value={password}
                                autocomplete="off"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <img className="label-login" src='/images/locked-outline.png' alt=""/>
                        </div>

                        <div className="group">
                            {/* <label htmlFor="confirm_password_field">Confirm Password</label> */}
                            <input
                                type="password"
                                id="confirm_password_field"
                                className="login-input"
                                autocomplete="off"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <img className="label-login" src='/images/locked-outline.png' alt=""/>
                        </div>
                        <div className="div_button"> 
                            <button
                                id="new_password_button"
                                type="submit"
                                className="btn btn-block py-3">
                                SET PASSWORD
                            </button>
                        </div>

                    </form>
                </div>
            </div>

        </Fragment>
    )
}

export default NewPassword