import React, {Component} from 'react'
import './TabsBrew.scss';

class Tabs extends Component {
    constructor(props) {
      super();
      this.state = {
        active: 0
      }
    }
    
    select = (i) => {
      let _this = this;
      return function() {
        _this.setState({
          active: i
        });
      }
    }
    
    renderTabs = () => {
      return React.Children.map(this.props.children, (item, i) => {
        if (i%2 === 0) {
          let active = this.state.active === i ? 'active' : '';
          return <button onClick={this.select(i)} className={`${active} tab`}>{item}</button>;
        }
      });
    }
    
    renderContent() {
      return React.Children.map(this.props.children, (item, i) => {
        if (i-1 === this.state.active) {
          return <div className='content'>{item}</div>;
        } else {
          return;
        }
      });
    }
    
    render() {
      return (
        <div className="tabs-brew">
          {this.renderTabs()}
          {this.renderContent()}
        </div>
      );
    }
  }
  
  class ChemexTab extends Component {
    render() {
      return(
        <main>
          <Tabs>
            1 CUP
            <span>
              <ul className="tabs-brew-list">
                <li>Chemex 6-cup brewer</li>
                <li>Square Chemex filter</li>
                <li>24 grams (4.5 Tbsp) of coarse craft coffee (setting 5 grind)</li>
                <li>340 grams of water at 205F, plus additional for rinsing</li>
                <li>Pouring Kettle (gooseneck preferred)</li>
                <li>Burr grinder (recommended)</li>
                <li>Scale (recommended)</li>
                <li>Coffee to water ratio is 5:68</li>
            </ul>
            </span>
            2 CUPS
            <span>
              <ul className="tabs-brew-list">
                  <li>Chemex 6-cup brewer</li>
                  <li>Square Chemex filter</li>
                  <li>35 grams (5.5 Tbsp) of coarse grind craft coffee (setting 5 grind)</li>
                  <li>525 grams of water , plus additional for rinsing filter</li>
                  <li>Pouring Kettle (gooseneck preferred)</li>
                  <li>Burr grinder (recommended)</li>
                  <li>Scale (recommended)</li>
                  <li>Coffee to water ratio is 1:15</li>
              </ul>
            </span>
            {/* 6 CUPS
            <span>
              <ul className="tabs-brew-list">
                  <li>Chemex 6-cup brewer</li>
                  <li>Square Chemex filter</li>
                  <li>42 grams of coarse Craft Coffee beans</li>
                  <li>890 grams of water (30 oz), plus additional for rinsing filter</li>
                  <li>Pouring Kettle (gooseneck preferred)</li>
                  <li>Burr grinder (recommended)</li>
                  <li>Scale (recommended)</li>
              </ul>
            </span>
            8 CUPS
            <span>
              <ul className="tabs-brew-list">
                  <li>Chemex 6-cup brewer</li>
                  <li>Square Chemex filter</li>
                  <li>64 grams grams of coarse Craft Coffee beans</li>
                  <li>1180 grams of water (40 oz), plus additional for rinsing filter</li>
                  <li>Pouring Kettle (gooseneck preferred)</li>
                  <li>Burr grinder (recommended)</li>
                  <li>Scale (recommended)</li>
              </ul>
            </span> */}
          </Tabs>
        </main>
      );
    }
  }
 
export default ChemexTab;